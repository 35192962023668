import { FunctionComponent, useContext } from "react";
import { InfoBox } from "../../components/Styles";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { AnalyzeConfigInfo } from "./AnalyzeConfigInfo";
import { InstallingAnalyzeAgentInfo } from "./InstallingAnalyzeAgentInfo";
import { InstallingAnalyzeServerInfo } from "./InstallingAnalyzeServerInfo";
import { UserContext } from "../../components/User/UserProvider";
import { Redirect } from "react-router-dom";
import { GET_COMPANY_AS_ADMIN, useCompanyAsAdminQuery } from "../../graphql";
import { UPDATE_COMPANY_ANALYZE_ADDRESS } from "../../graphql/Mutations/Companies";
import { useCompanyLicensesQuery } from "../../graphql/Queries/Licenses/GetCompanyLicenses";
import Parser from "html-react-parser";

export const ConfigureAnalyze: FunctionComponent = () => {
    const { t } = useContext(ApplicationContext);
    const { companyId } = useContext(UserContext);
    const { company } = useCompanyAsAdminQuery();
    const helpInstallationGuideLink = `https://help.grabcad.com/article/290-setting-up-grabcad-analyze-installation`;

    const { enabledFeatures } = useCompanyLicensesQuery();

    if (!enabledFeatures.analyzeAccess) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <div style={{ display: "grid" }}>
                <h2 className="page-header">{t("configure_analyze.grabcad_analyze")}</h2>
                <InfoBox>
                    {Parser(
                        t("configure_analyze.info", {
                            installation_guide: helpInstallationGuideLink,
                        })
                    )}
                </InfoBox>

                <AnalyzeConfigInfo
                    mutation={UPDATE_COMPANY_ANALYZE_ADDRESS}
                    analyzeAddressUrl={company?.analyzeServerAddress}
                    refetchQueries={[{ query: GET_COMPANY_AS_ADMIN, variables: { id: companyId } }]}
                />
                <h2 style={{ marginTop: "56px" }}>{t("configure_analyze.installation")}</h2>

                <hr style={{ margin: "10px 0px" }} />
                <InstallingAnalyzeAgentInfo />
                <InstallingAnalyzeServerInfo />
            </div>
        </>
    );
};
