import React, { FunctionComponent } from "react";
import gql from "graphql-tag";
import classnames from "classnames";
import { Query } from "@apollo/client/react/components";
import { ApolloError, QueryOptions, QueryResult } from "@apollo/client";
import { Dropdown } from "grabcad-ui-elements";
import { Redirect } from "react-router-dom";
import { Notifier } from "../../view/Notifier";
import { alphabetizeByProp } from "../../utils/sort";
import { Company, FeatureData, LicenseData } from "../../graphql";
import { Properties } from "csstype";

export const LICENSE_PACKAGES = gql`
    query listLicensePackages {
        listLicensePackages {
            packages {
                id
                name
                disabled
                partNumber
                maxUsers
                unlimitedMaxUsers
                companyWide
                duration
                product {
                    id
                    features {
                        id
                        key
                        isPrinterType
                        isMaterialType
                    }
                }
            }
        }
    }
`;

export interface LicensePackagesQueryResult {
    listLicensePackages: {
        packages: {
            id: number;
            name: string;
            disabled: boolean;
            partNumber: string;
            maxUsers: number;
            unlimitedMaxUsers?: boolean;
            companyWide?: boolean;
            duration: string;
            product: {
                id: number;
                features: Pick<FeatureData, "id" | "key" | "isPrinterType" | "isMaterialType">[];
            };
            companies: Pick<Company, "id" | "name">[];
            licenses: Pick<LicenseData, "id">[];
        }[];
    };
}

export interface LicensePackagesQueryProps extends Omit<QueryOptions, "query"> {
    children: (result: QueryResult<LicensePackagesQueryResult>) => JSX.Element;
    name?: string;
    skip?: boolean;
    onCompleted?: (data: LicensePackagesQueryResult) => void;
    onError?: (error: ApolloError) => void;
}

export class LicensePackagesQuery extends React.Component<LicensePackagesQueryProps> {
    render() {
        const { children, ...props } = this.props;

        return (
            <Query<LicensePackagesQueryResult>
                query={LICENSE_PACKAGES}
                {...props}
                fetchPolicy="no-cache"
            >
                {children}
            </Query>
        );
    }
}

interface ILicensePackageDropdown {
    licensePackageId: number | undefined;
    onChange: (licensePackageJson: string) => void;
    disabled?: boolean;
    style?: Properties<string | number>;
    invalid: boolean;
}

export const LicensePackageDropdown: FunctionComponent<ILicensePackageDropdown> = (props) => {
    return (
        <LicensePackagesQuery>
            {({ loading, error, data }) => {
                if (loading) {
                    return <div className="ui loader active" />;
                }
                if (error) {
                    Notifier.error(error);
                    return <Redirect to="/license_package" />;
                }
                const packages = data?.listLicensePackages?.packages
                    ? data.listLicensePackages.packages
                          .sort(alphabetizeByProp("name"))
                          .map((licensePackage) => ({
                              key: licensePackage.id,
                              text: licensePackage.name,
                              value: JSON.stringify(licensePackage),
                              disabled: licensePackage.disabled,
                          }))
                          .filter((licensePackage) => !licensePackage.disabled)
                    : [];
                const getDefaultValue = () => {
                    const licensePackageId = props.licensePackageId;
                    const defaultPackage = packages.find(
                        (licensePackage) => licensePackage.key === licensePackageId
                    );
                    return defaultPackage ? defaultPackage.value : "";
                };
                const dropDownProps = {
                    id: "qa-licensePackages",
                    disabled: !packages.length || props.disabled,
                    placeholder: !packages.length
                        ? "No license packages defined"
                        : "Select license package",
                    defaultValue: getDefaultValue(),
                    options: packages,
                    style: props.style,
                    fluid: true,
                    selection: true,
                    search: true,
                };

                return (
                    <>
                        <label>
                            License Package Name
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </label>
                        <Dropdown
                            {...dropDownProps}
                            className={classnames({ error: props.invalid })}
                            onChange={(e, newData) => {
                                props.onChange(newData.value as any);
                            }}
                        />
                        {props.invalid && (
                            <p style={{ marginRight: 30 }} className="ui negative message">
                                {"This field is required."}
                            </p>
                        )}
                    </>
                );
            }}
        </LicensePackagesQuery>
    );
};
