import styled from "grabcad-ui-elements";
import { PrinterSerial } from "./PrinterSerial";
import { useContext } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { useCompanyAsAdminQuery } from "../../graphql";

const Divider = styled.hr`
    margin: 8px;
    border: 1px solid #4183c4;
`;
export const PrinterGateways = () => {
    const { t } = useContext(ApplicationContext);
    const { company, companyLoading } = useCompanyAsAdminQuery();

    if (companyLoading || !company?.customerInfo?.accountNumber) {
        return <></>;
    }

    return (
        <>
            <Divider data-testid="divider" />
            <h2 className="page-header" data-testid="page-header">
                {t("gateways.gateways_title")}
            </h2>
            <PrinterSerial data-testid="printer-serial"></PrinterSerial>
        </>
    );
};
