import { TranslationFunction } from "../../components/ApplicationProvider";
import { IGateway } from "../../graphql";

export function findExternalCompanyIdKey(gatewayListData: IGateway[]): string | undefined {
    if (Array.isArray(gatewayListData) && gatewayListData.length > 0) {
        const fingGatewayItem = gatewayListData.find((item) =>
            item.hasOwnProperty("externalCompanyId")
        );
        if (fingGatewayItem) {
            return fingGatewayItem?.externalCompanyId;
        }
    }
    return undefined;
}

export default function renderOwnedByLabels({
    companiesListItem,
    showAllCompaniesList,
    toggleShowAll,
    t,
}: {
    companiesListItem: { id: number; name: string }[];
    showAllCompaniesList: boolean;
    toggleShowAll: () => void;
    t: TranslationFunction;
}) {
    // If no companies exist
    if (!companiesListItem?.length) {
        return (
            <label data-testid="printer_owned_by_label">
                {t("gateways.owned_by")}: {t("gateways.gateways_no_site")}
            </label>
        );
    }

    // Render company labels
    const visibleCompanies = showAllCompaniesList
        ? companiesListItem
        : companiesListItem.slice(0, 1);

    const companyLabels = visibleCompanies.map((item, index) => (
        <label data-testid={`printer_owned_by_label_${index}`} key={item.id}>
            {t("gateways.owned_by")}: {item.name}
        </label>
    ));

    // Render toggle link
    const toggleLink =
        companiesListItem.length > 1 ? (
            <a onClick={toggleShowAll}>
                {showAllCompaniesList
                    ? t("gateways.show_less")
                    : `+${companiesListItem.length - 1} ${t("gateways.more")}`}
            </a>
        ) : null;

    return (
        <>
            {companyLabels}
            {toggleLink}
        </>
    );
}
