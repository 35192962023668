import gql from "graphql-tag";
import { useQueryWithCatch } from "../../../utils/hooks/useQueryWithCatch";

// Define the GraphQL query
export const GET_COMPANIES_BY_EXTERNAL_ID = gql`
    query getCompaniesByExternalId($externalId: String!) {
        getCompaniesByExternalId(externalId: $externalId) {
            companies {
                id
                name
            }
        }
    }
`;

// Types for the query variables
export interface CompaniesVariables {
    externalId: string; // externalId is required here
}

export interface companyItem {
    id: number;
    name: string;
}

// Types for the query result
export interface ListCompaniesQueryResult {
    getCompaniesByExternalId: {
        companies: companyItem[];
    };
}

export const useGetCompaniesByExternalId = (externalId: string) => {
    const { data } = useQueryWithCatch<ListCompaniesQueryResult, CompaniesVariables>(
        GET_COMPANIES_BY_EXTERNAL_ID,
        { variables: { externalId } }
    );
    return {
        data,
    };
};
