import { useState, useContext, useRef } from "react";
import * as _ from "lodash";
import {
    Button,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableCell,
    TableBody,
    Icon,
    Dropdown,
} from "grabcad-ui-elements";
import { LicenseData, Company } from "../../graphql";
import { Notifier } from "../../view/Notifier";
import {
    LIST_COMPANIES,
    ListCompaniesQueryResult,
    ListCompaniesVariables,
} from "../../graphql/Queries/Companies/ListCompanies";
import { PaginationLinks } from "../../view/Navigation/PaginationLinks";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";
import { applicationLocale } from "../../i18n";
import { ApplicationContext } from "../../components/ApplicationProvider";
import {
    LIGHT_BLUE,
    LIGHT_GREEN,
    LIGHT_RED,
    LIGHT_YELLOW,
    TRANSPARENT,
} from "../../components/Styles";
import { DeleteEntityConfirmation } from "../../view/Modals/DeleteEntityConfirmation";
import { DELETE_LICENSE } from "../../components/license/LicenseTable";
import { IScreenProps } from "../Screen";
import styled from "styled-components";
import { licesneStatusOptions } from "../../utils/constants";

interface GetCompaniesProps extends IScreenProps {
    packageId: number;
}

const LicenseCompanyList: React.FC<GetCompaniesProps> = ({
    packageId,
    history,
}: GetCompaniesProps) => {
    const COMPANIES_PER_PAGE = 10;
    const [page, setPage] = useState(1);
    const [licenseToDelete, setLicenseToDelete] = useState<LicenseData>();
    const { t } = useContext(ApplicationContext); // For translation
    const locale = applicationLocale();
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascending" | "descending" | undefined>(
        undefined
    );
    const [statusFilter, setStatusFilter] = useState<string>("");
    const [isDropdownVisible, setDropdownVisible] = useState(false); // State for dropdown visibility
    const dropdownRef = useRef(null); // Reference for the dropdown

    const { loading, data, refetch } = useQueryWithCatch<
        ListCompaniesQueryResult,
        ListCompaniesVariables
    >(LIST_COMPANIES, {
        variables: {
            skip: COMPANIES_PER_PAGE * (page - 1),
            take: COMPANIES_PER_PAGE,
            licensePackageId: packageId,
        },
        fetchPolicy: "network-only",
        skip: !packageId, // This will skip the query if packageId is not available
    });

    const handleSort = (column: string) => {
        setSortedColumn(column);
        setSortDirection((prev) =>
            sortedColumn === column && prev === "ascending" ? "descending" : "ascending"
        );
    };

    if (loading || !data) {
        return <div className="ui loader active" />;
    }

    const { companies, totalCount } = data.listCompanies;

    if (totalCount === 0) {
        return null;
    }

    const sortedCompanies = (companyList: Company[]): Company[] => {
        const columnMappings: Record<string, keyof Company | keyof LicenseData> = {
            id: "id",
            name: "name",
            endDate: "endDate", // Will be handled through licenses
            seatsUsed: "seatsUsed", // Will be handled through licenses
        };

        if (!sortedColumn || !columnMappings[sortedColumn]) {
            return companyList;
        }

        const key = columnMappings[sortedColumn];
        const direction = sortDirection || "ascending";

        return [...companyList].sort((a, b) => {
            let valueA: any;
            let valueB: any;

            // Handle license-specific sorting keys (like endDate and seatsUsed)
            if (key === "endDate" || key === "seatsUsed") {
                const licenseA = a.licenses?.[0];
                const licenseB = b.licenses?.[0];

                valueA = licenseA?.[key] || ""; // Default to empty string if undefined
                valueB = licenseB?.[key] || "";
            } else {
                valueA = a[key as keyof Company];
                valueB = b[key as keyof Company];
            }

            let comparison = 0;

            if (valueA !== undefined && valueB !== undefined) {
                if (typeof valueA === "string" && typeof valueB === "string") {
                    comparison = valueA.localeCompare(valueB, undefined, {
                        sensitivity: "base",
                    });
                } else if (typeof valueA === "number" && typeof valueB === "number") {
                    comparison = valueA - valueB;
                }
            } else {
                comparison = valueA === undefined ? -1 : 1;
            }

            return direction === "descending" ? -comparison : comparison;
        });
    };

    const renderActionColumnHeader = () => (
        <TableHeaderCell>{t("license_package_table.action")}</TableHeaderCell>
    );

    const handleDeleteClick = (license: LicenseData) => {
        if (licenseToDelete?.id !== license.id) {
            setLicenseToDelete(license);
        }
    };
    const renderActionColumnData = (license: LicenseData, companyId: number) => {
        const appType = license.package?.product?.applicationType?.id ?? "";
        const canRenew = license.state === "expired" && !appType.toLowerCase().includes("shop");

        return (
            <TableCell
                className="table-header-actions"
                data-testid={`action-cell-license-${license.id}`}
            >
                <a
                    onClick={() => handleDeleteClick(license)}
                    data-testid={`delete-license-${license.id}`}
                >
                    {t("license_package_table.delete")}
                </a>
                {" | "}
                {license.state !== "expired" && license.endDate && (
                    <>
                        <a onClick={() => history.push(`/license/${license.id}`)}>
                            {t("license_package_table.extend")}
                        </a>
                    </>
                )}
                {canRenew && (
                    <>
                        <a
                            onClick={() =>
                                history.push(`/license/assign/${companyId}?renewFrom=${license.id}`)
                            }
                        >
                            {`${t("license_package_table.renew")}`}
                        </a>
                    </>
                )}
            </TableCell>
        );
    };

    const getLicenseStateColor = (state: string) => {
        const stateColors: Record<string, string> = {
            active: LIGHT_GREEN,
            expiring: LIGHT_YELLOW,
            expired: LIGHT_RED,
            not_started: LIGHT_BLUE,
        };
        return stateColors[state] || TRANSPARENT;
    };

    const renderDeleteLicenseConfirmation = () => (
        <DeleteEntityConfirmation
            entity={{ id: licenseToDelete?.id, name: licenseToDelete?.package.name }}
            type="license"
            mutation={DELETE_LICENSE}
            refetchQueries={[
                {
                    query: LIST_COMPANIES,
                    variables: {
                        skip: COMPANIES_PER_PAGE * (page - 1),
                        take: COMPANIES_PER_PAGE,
                        licensePackageId: packageId,
                    },
                },
            ]}
            update={() => {
                void refetch();
                Notifier.success("Successfully deleted license");
            }}
            open={!!licenseToDelete} // Ensure modal opens only if there's a license to delete
            onClose={() => setLicenseToDelete(undefined)} // Clear state to prevent reopening
            data-testid={`delete-confirmation-modal-${licenseToDelete?.id}`} // Unique data-testid for modal
        />
    );

    const handleFilterChange = (status: string) => {
        setStatusFilter(status);
    };

    const filteredCompanies = () => {
        return companies.filter((company) => {
            if (!statusFilter || statusFilter === "all") {
                return true;
            }
            return company.licenses?.some(
                (license) => license.state.toLowerCase() === statusFilter?.toLowerCase()
            );
        });
    };

    // Define a styled component for the filter icon
    const FilterIcon = styled(Icon)`
        cursor: pointer;
        padding-left: 10px;
        display: inline-block;
    `;

    // Define a styled component for the dropdown
    const StyledDropdown = styled(Dropdown)`
        display: inline-block;
        margin-left: 20px;
    `;

    // Dropdown component for filtering
    const FilterDropdown = () => (
        <>
            <FilterIcon
                name="filter"
                onClick={() => setDropdownVisible(!isDropdownVisible)}
                data-testid={`filter-icon`}
            />
            {isDropdownVisible && (
                <StyledDropdown
                    ref={dropdownRef}
                    options={licesneStatusOptions}
                    onChange={(_e: React.SyntheticEvent, { value }: { value: string }) =>
                        handleFilterChange(value)
                    }
                    value={statusFilter || "all"}
                    data-testid="dropdown-filter"
                />
            )}
        </>
    );

    return (
        <Table sortable>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell
                        onClick={() => handleSort("id")}
                        sorted={sortedColumn === "id" ? sortDirection : undefined}
                    >
                        {t("license_package_table.id")}
                    </TableHeaderCell>
                    <TableHeaderCell
                        onClick={() => handleSort("name")}
                        sorted={sortedColumn === "name" ? sortDirection : undefined}
                    >
                        {t("license_package_table.company_name")}
                    </TableHeaderCell>
                    <TableHeaderCell
                        onClick={() => handleSort("endDate")}
                        sorted={sortedColumn === "endDate" ? sortDirection : undefined}
                    >
                        {t("license_package_table.end_date")}
                    </TableHeaderCell>
                    <TableHeaderCell
                        onClick={() => handleSort("seatsUsed")}
                        sorted={sortedColumn === "seatsUsed" ? sortDirection : undefined}
                    >
                        {t("license_package_table.used_seats")}
                    </TableHeaderCell>
                    <TableHeaderCell>
                        {t("license_package_table.status")}
                        <FilterDropdown />
                    </TableHeaderCell>
                    {renderActionColumnHeader()}
                </TableRow>
            </TableHeader>
            <TableBody>
                {sortedCompanies(filteredCompanies()).map((company) =>
                    company.licenses?.map((license, index) => {
                        const endDate = license.endDate
                            ? new Date(license.endDate).toLocaleDateString(locale)
                            : "-";
                        const seatsUsed = license.seatsUsed || "-";
                        const licenseStateText = `${t(`license.states.${license.state}`)}${
                            license.package.companyWide ? `, ${t("license.company_wide")}` : ""
                        }`;
                        const licenseStateColor = getLicenseStateColor(license.state);

                        return (
                            <TableRow key={`${company.id}-${license.id}`}>
                                {/* Render Company ID and Name in the first row and span across multiple license rows */}
                                {index === 0 && (
                                    <>
                                        <TableCell
                                            rowSpan={company?.licenses?.length}
                                            className="custom-link"
                                            onClick={() => history.push(`/company/${company.id}`)}
                                        >
                                            {company.id}
                                        </TableCell>
                                        <TableCell rowSpan={company?.licenses?.length}>
                                            {company.name}
                                        </TableCell>
                                    </>
                                )}

                                {/* License-specific data */}
                                <TableCell>{endDate}</TableCell>
                                <TableCell>{seatsUsed}</TableCell>
                                <TableCell>
                                    <Button
                                        fluid
                                        style={{
                                            backgroundColor: licenseStateColor,
                                            color: "#000000",
                                            fontWeight: "normal",
                                            border: "none",
                                            justifyContent: "center",
                                            cursor: "default",
                                        }}
                                    >
                                        {licenseStateText}
                                    </Button>
                                </TableCell>

                                {/* Actions and License deletion */}
                                <TableCell>{renderActionColumnData(license, company.id)}</TableCell>
                            </TableRow>
                        );
                    })
                )}
            </TableBody>
            {renderDeleteLicenseConfirmation()}
            <div className="footer">
                <PaginationLinks
                    total={totalCount}
                    perPage={COMPANIES_PER_PAGE}
                    currentPage={page}
                    goToPage={setPage}
                />
            </div>
        </Table>
    );
};

export default LicenseCompanyList;
