import { FunctionComponent, useContext, useState } from "react";
import { match, Redirect } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { IScreenProps } from "./Screen";
import {
    User,
    LIST_USERS,
    UPDATE_USER,
    LIST_USER_GROUPS,
    REMOVE_USER_FROM_COMPANY,
    useUsersQuery,
    useUserGroupsQuery,
    Company,
} from "../graphql";
import { Breadcrumbs } from "../view/Navigation/BreadCrumbs";
import { PATHS } from "../Routes";
import { UserGroupMultiSelect } from "../view/UserGroupMultiSelect";
import { Notifier } from "../view/Notifier";
import styled, { Button, Modal, Header, Icon, Loader } from "grabcad-ui-elements";
import { UserContext } from "../components/User/UserProvider";
import { ApplicationContext } from "../components/ApplicationProvider";
import { GET_COMPANY_AS_ADMIN } from "../graphql/Queries/Company";
import { UPDATE_COMPANY } from "../graphql/Mutations/Companies";
import { config } from "../config";
import { applicationLocale } from "../i18n";
import { GET_COMPANY_LICENSES } from "../graphql/Queries/Licenses/GetCompanyLicenses";

interface IEditUserProps extends IScreenProps {
    match: match<{ email?: string }>;
}

const UserGroupsContainer = styled.div`
    margin: 0 0 1em;

    label {
        display: block;
        margin: 0 0 0.28571429rem 0;
        font-size: 0.92857143em;
        font-weight: 700;
        text-transform: none;
    }
`;

export const EditUser: FunctionComponent<IEditUserProps> = (props) => {
    const email = props.match.params.email as string;
    const { t } = useContext(ApplicationContext);
    const { users, loadingUsers } = useUsersQuery();
    const { userGroups } = useUserGroupsQuery();
    const userContext = useContext(UserContext);
    const userIsAdmin = userContext.email === email;
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [showGlobalAdminModal, setShowGlobalAdminModal] = useState(false);
    const [updateUser] = useMutation(UPDATE_USER);
    const companyId = userContext.companyId || parseInt((props as any).match.params.id, 10);

    const breadCrumbs = (
        <Breadcrumbs
            sections={[{ label: t("users.breadcrumb"), to: PATHS.users }, { label: email }]}
        />
    );

    if (loadingUsers) {
        return (
            <>
                {breadCrumbs}
                <Loader active />
            </>
        );
    }

    const user = users.find((u) => u.email === email);
    if (!user) {
        Notifier.error(new Error(`${t("users.invalid_email.single_truncated")}${email}`));
        return <Redirect to="/" />;
    }

    const groupIds = userGroups
        .filter((group) => group.users.find((u) => u.id === user.id))
        .map((group) => group.id);

    return (
        <div>
            {breadCrumbs}
            <h1 className="qa-user-header">{user.name || user.email}</h1>
            <div>
                {showRemoveModal && (
                    <RemoveUserFromCompanyModal
                        user={user}
                        onClose={() => setShowRemoveModal(false)}
                        onUpdate={() => props.history.push(PATHS.users)}
                    />
                )}
                {showGlobalAdminModal && (
                    <UpdateAdminForCompanyModal
                        companyId={companyId}
                        user={user}
                        onClose={() => setShowGlobalAdminModal(false)}
                        onUpdate={() => props.history.push(PATHS.users)}
                    />
                )}

                <table className="ui table qa-userDetalis">
                    <thead>
                        <tr>
                            <th>{t("users.name_header")}</th>
                            <th>{t("users.email_header")}</th>
                            <th>{t("users.join_date_header")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-label="Name" className="qa-user-name">
                                {user.name}
                            </td>
                            <td data-label="Users" className="qa-user-email">
                                {user.email}
                            </td>
                            <td data-label="Joined Date" className="qa-user-joindate">
                                {!!user.joinDate
                                    ? new Date(user.joinDate).toLocaleString(applicationLocale())
                                    : ""}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <UserGroupsContainer>
                    <UserGroupMultiSelect
                        ids={groupIds}
                        onChange={(ids) => {
                            void updateUser({
                                variables: {
                                    groupIdsToAdd: ids.filter((id) => !groupIds.includes(id)),
                                    groupIdsToRemove: groupIds.filter((id) => !ids.includes(id)),
                                    currentEmail: user.email,
                                },
                                update: () => {
                                    Notifier.success(
                                        t("users.successfully_updated", {
                                            name: user.name,
                                        })
                                    );
                                },
                                refetchQueries: [{ query: LIST_USER_GROUPS }],
                            });
                        }}
                    />
                </UserGroupsContainer>

                <Button
                    className="negative right floated qa-user-remove"
                    onClick={() =>
                        userIsAdmin ? setShowGlobalAdminModal(true) : setShowRemoveModal(true)
                    }
                >
                    {t("users.remove_from_company")}
                </Button>
            </div>
        </div>
    );
};

interface IRemoveUserFromCompanyModalProps {
    user: User;
    onClose: () => void;
    onUpdate?: () => void;
}
export const RemoveUserFromCompanyModal: FunctionComponent<IRemoveUserFromCompanyModalProps> = (
    props
) => {
    const { t } = useContext(ApplicationContext);
    const [removeUserFromCompany] = useMutation<boolean, { userId: number }>(
        REMOVE_USER_FROM_COMPANY
    );

    return (
        <Modal basic size="small" open={true} onClose={props.onClose}>
            <Header icon="trash" content={t("remove_user_modal.header")} />
            <Modal.Content>
                <h3>{props.user.name}</h3>
                <p>{t("remove_user_modal.content")}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button className="qa-removeUserModal-cancel" secondary onClick={props.onClose}>
                    <Icon name="remove" /> {t("forms.cancel")}
                </Button>
                <Button
                    className="qa-removeUserModal-remove"
                    negative
                    onClick={() => {
                        removeUserFromCompany({
                            variables: {
                                userId: props.user.id,
                            },
                            refetchQueries: [
                                { query: LIST_USERS },
                                { query: LIST_USER_GROUPS },
                                { query: GET_COMPANY_LICENSES },
                            ],
                            update: () => {
                                Notifier.success(
                                    t("users.successfully_removed", {
                                        name: props.user.name,
                                    })
                                );
                                props.onClose();
                                if (props.onUpdate) {
                                    props.onUpdate();
                                }
                            },
                        }).catch(Notifier.error);
                    }}
                >
                    <Icon name="checkmark" /> {t("remove_user_modal.remove")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const ButtonWrapper = styled.div`
    margin-left: 0rem;
`;

const deleteFromCompanyAdmins = (email: string, company: Company): string[] => {
    const admins: string[] = company.admins.map((e: any) => e.email);
    const index: number = admins.indexOf(email);
    if (index > -1) {
        admins.splice(index, 1);
    }

    return admins;
};
interface IUpdateAdminForCompanyModalProps {
    companyId: number;
    user: User;
    onClose: () => void;
    onUpdate?: () => void;
}
export const UpdateAdminForCompanyModal: FunctionComponent<IUpdateAdminForCompanyModalProps> = (
    props
) => {
    const { t } = useContext(ApplicationContext);
    const [removeUserFromCompany] = useMutation<boolean, { userId: number }>(
        REMOVE_USER_FROM_COMPANY
    );
    const [updateCompany] = useMutation<any, { id: number; admins: string[]; name: string }>(
        UPDATE_COMPANY
    );
    const { loading, data, error } = useQuery<{ company: Company }>(GET_COMPANY_AS_ADMIN, {
        variables: { id: props.companyId },
    });

    if (loading) {
        return (
            <>
                <Loader active />
            </>
        );
    }

    if (error || !data) {
        Notifier.error(error ?? new Error("Company query error"));
        return <></>;
    }

    return (
        <Modal basic size="small" open={true} onClose={props.onClose}>
            <Header icon="trash" content={t("remove_user_modal.header")} />
            <Modal.Content>
                <h3>{props.user.name}</h3>
                <p>{t("users.prompt_to_remove")}</p>
            </Modal.Content>
            <Modal.Actions>
                <ButtonWrapper>
                    <Button className="qa-removeUserModal-cancel" secondary onClick={props.onClose}>
                        <Icon name="remove" /> {t("forms.cancel")}
                    </Button>
                    <Button
                        className="qa-removeUserModal-remove"
                        negative
                        onClick={() => {
                            const adminsArray = deleteFromCompanyAdmins(
                                props.user.email,
                                data.company
                            );
                            void updateCompany({
                                variables: {
                                    id: props.companyId,
                                    admins: adminsArray,
                                    name: data.company.name,
                                },
                            }).then(() => {
                                window.location.assign(`${config.REACT_APP_LOGOUT_URL}`);
                            });
                        }}
                    >
                        <Icon name="checkmark" /> Convert to non-admin
                    </Button>
                    <Button
                        className="qa-removeUserModal-remove" // TODO GC-93424: This className is doubled on this page! Use unique `data-test-id`s instead
                        negative
                        onClick={() => {
                            removeUserFromCompany({
                                variables: {
                                    userId: props.user.id,
                                },
                                refetchQueries: [
                                    { query: LIST_USERS },
                                    { query: LIST_USER_GROUPS },
                                    { query: GET_COMPANY_LICENSES },
                                ],
                                update: () => {
                                    Notifier.success(
                                        t("users.successfully_removed", {
                                            name: props.user.name,
                                        })
                                    );
                                    props.onClose();
                                    if (props.onUpdate) {
                                        props.onUpdate();
                                    }
                                },
                            }).catch(Notifier.error);
                        }}
                    >
                        <Icon name="checkmark" /> {t("remove_user_modal.remove")}
                    </Button>
                </ButtonWrapper>
            </Modal.Actions>
        </Modal>
    );
};
