import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "@apollo/client";
import { DetailedLicenseData } from "../../types"; //
import { LicenseFragments } from "../../Fragments/License";
import { Notifier } from "../../../view/Notifier";

export const GET_COMPANY_LICENSES = gql`
    query companyLicenses($companyId: Int) {
        companyLicenses(companyId: $companyId) {
            licenses {
                ...licenseFields
                package {
                    id
                    name
                    unlimitedMaxUsers
                    companyWide
                    disabled
                    product {
                        id
                        name
                        disabled
                        features {
                            key
                            disabled
                        }
                        applicationType {
                            id
                            description
                        }
                        sdk {
                            id
                            icon
                        }
                    }
                }
                licenseAssignments {
                    user {
                        id
                        name
                        email
                    }
                    dateAssigned
                    dateRevoked
                }
                groups {
                    id
                    name
                    roles {
                        application {
                            name
                        }
                        applicationRole {
                            name
                        }
                    }
                }
            }
            totalCount
        }
    }
    ${LicenseFragments.licenseFields}
`;
export interface GetCompanyLicensesQueryVariables {
    companyId?: number;
}

export interface GetCompanyLicensesQueryResult {
    companyLicenses: {
        licenses: DetailedLicenseData[];
        // TODO: GC-93985 // totalCount: number;
    };
}

export function useCompanyLicensesQuery(
    options?: QueryHookOptions<GetCompanyLicensesQueryResult, GetCompanyLicensesQueryVariables>
) {
    const { data, loading, error } = useQuery<
        GetCompanyLicensesQueryResult,
        GetCompanyLicensesQueryVariables
    >(GET_COMPANY_LICENSES, { ...options });

    if (error) {
        Notifier.error(error);
    }

    const licenses = data?.companyLicenses.licenses || [];
    return {
        loadingLicenses: loading,
        companyLicenses: data?.companyLicenses,

        /**
         * EnabledFeatures is a set of explicitly declared flags for certain specific features.
         * These have been called out specifically for convenience, performance or because they
         * depend on application settings rather than product features. There is no need
         * to add all possible features in here
         */
        enabledFeatures: {
            sdkAccess: hasEnabledApplication(licenses, SDK_APP_TYPE_KEY),
            stigAccess: hasEnabledFeature(licenses, STIG_ACCESS_KEY),
            accessControl: hasEnabledFeature(licenses, ACCESS_CONTROL_KEY),
            analyzeAccess: hasEnabledFeature(licenses, ANALYZE_FEATURE_KEY),
        },
        /**
         * An array of all the enabled feature keys for the current user. Useful for pass through
         * for example to the navbar without having to write specific code for each one
         */
        allEnabledFeatureKeys: allEnabledFeatureKeys(licenses),
    };
}

export const STIG_ACCESS_KEY = "stig_access";
export const SDK_APP_TYPE_KEY = "sdk";
export const PRINTERS_PAGE_BETA_ACCESS_KEY = "printers_page_beta";
export const ACCESS_CONTROL_KEY = "access_control";
export const ZEUS_STRATASYS_CSE = "zeus_stratasys_cse";
export const ANALYZE_FEATURE_KEY = "analyze_user";

function hasEnabledFeature(licenses: DetailedLicenseData[], key: string) {
    return (
        licenses.some(
            (license) =>
                license.state !== "expired" &&
                license.state !== "not_started" &&
                !license.package.disabled &&
                license.package.product &&
                !license.package.product.disabled &&
                license.package.product.features?.some(
                    (feature) => feature && !feature.disabled && feature.key === key
                )
        ) ?? false
    );
}

function hasEnabledApplication(licenses: DetailedLicenseData[], key: string) {
    return (
        licenses.some(
            (license) =>
                license.state !== "expired" &&
                license.state !== "not_started" &&
                !license.package.disabled &&
                license.package.product &&
                !license.package.product.disabled &&
                license.package.product?.applicationType?.id === key
        ) ?? false
    );
}

function allEnabledFeatureKeys(licenses: DetailedLicenseData[]): Set<string> {
    const allFeatureKeys = new Set<string>();
    licenses.forEach((license) => {
        if (
            license.state !== "expired" &&
            license.state !== "not_started" &&
            !license.package.disabled &&
            license.package.product &&
            !license.package.product.disabled
        ) {
            license.package.product.features?.forEach((f) => {
                if (!f.disabled) {
                    allFeatureKeys.add(f.key);
                }
            });
        }
    }, new Set<string>());
    return allFeatureKeys;
}
