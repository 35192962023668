import "./App.css";
import "semantic-ui-css/semantic.min.css";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route } from "react-router-dom";
import { GraphQLClient } from "./GraphQLClient";
import { Routes } from "./Routes";
import { GlobalStyles, ThemeProvider, loadTheme, Notifier } from "grabcad-ui-elements";
import { i18n } from "./i18n";
import { UserProvider } from "./components/User/UserProvider";
import { ErrorBoundary } from "./screens/ErrorBoundary";
import { ApplicationProvider } from "./components/ApplicationProvider";
import isSdkPage from "./utils/isSdkPage";
import { GrabCADApplicationColors } from "grabcad-ui-elements/dist/Theme";
import { useEffect, useState } from "react";
import { extractGenericDomain } from "./utils/genericDomainExtracation";

export const App = () => {
    const [currentTheme, setCurrentTheme] = useState<string>(
        isSdkPage() ? GrabCADApplicationColors.GC_DEVELOP : GrabCADApplicationColors.GC_CONTROL
    );

    const refreshTheme = () => {
        // this confusing if statement is to avoid re-rendering
        const isSdk = isSdkPage();
        if (
            (isSdk && currentTheme === GrabCADApplicationColors.GC_DEVELOP) ||
            (!isSdk && currentTheme === GrabCADApplicationColors.GC_CONTROL)
        ) {
            return;
        }
        setCurrentTheme(
            isSdkPage() ? GrabCADApplicationColors.GC_DEVELOP : GrabCADApplicationColors.GC_CONTROL
        );
    };

    // This is pure tech debt
    // TODO: GC-93997 Remove this as a part of the permanent fix
    useEffect(() => {
        function redirectIfHasUrlPrefix() {
            const currentHostname = window.location.hostname;
            const redirectTo = extractGenericDomain(currentHostname);
            // This check will prevent unnecessary redirect if urlPrefix is not present
            if (redirectTo !== currentHostname) {
                window.location.replace(redirectTo);
            }
        }
        redirectIfHasUrlPrefix();
    }, []);

    return i18n(
        <ErrorBoundary>
            <ThemeProvider
                theme={loadTheme({
                    applicationSpecific: currentTheme,
                })}
            >
                <Notifier />
                <GlobalStyles />
                <ApplicationProvider>
                    <BrowserRouter>
                        <Route
                            render={({ location }) => (
                                <ApolloProvider client={GraphQLClient}>
                                    <UserProvider>
                                        <Routes location={location} refreshTheme={refreshTheme} />
                                    </UserProvider>
                                </ApolloProvider>
                            )}
                        />
                    </BrowserRouter>
                </ApplicationProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
};
