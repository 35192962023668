import ReactGA from "react-ga";
import { FunctionComponent, useContext, useEffect } from "react";
import { Route, Switch, NavLinkProps, Redirect } from "react-router-dom";
import { Shell } from "./screens/Screen";
import { CompanyList } from "./screens/Company/CompanyList";
import { CreateCompany, EditCompany } from "./screens/Company/Company";
import { CompanyAdminHomePage } from "./screens/CompanyAdmin/HomePage";
import { UserContext } from "./components/User/UserProvider";
import { EditUser } from "./screens/User";
import { CompanyUsers } from "./screens/Company/CompanyUsers";
import { CompanyUserGroups } from "./screens/Company/CompanyUserGroups";
import { CreateUserGroup, EditUserGroup } from "./screens/Company/UserGroup";
import { CreateClient, EditClient } from "./screens/Client/Client";
import { ClientList } from "./screens/Client/ClientList";
import { EditGlobalAdmins } from "./screens/GlobalAdmins";
import { config } from "./config";
import { ResetOnboarding } from "./screens/ResetOnboarding";
import { LicensePackageList } from "./screens/License/LicensePackageList";
import { CreateLicensePackage, EditLicensePackage } from "./screens/License/LicensePackage";
import { FeatureList } from "./screens/Feature/FeatureList";
import { CreateProduct, EditProduct } from "./screens/Product/Product";
import { ProductList } from "./screens/Product/ProductList";
import { CreateLicense, EditLicense } from "./screens/License/License";
import { LicensesOverview } from "./screens/License/LicensesOverview";
import { ProductLicenseDetails } from "./screens/Product/ProductLicenseDetails";
import { EditCompanyAccessOption } from "./screens/Company/CompanyAccessOption";
import { DomainRequestList } from "./screens/Company/DomainRequestList";
import { SdksOverview } from "./screens/SDK/SdksOverview";
import { Role } from "./graphql/types";
import { PrinterGroupsList } from "./screens/Printer/PrinterGroupsList";
import { CompanyPrinters } from "./screens/Printer/CompanyPrinters";
import { PrinterGroupList } from "./screens/Printer/PrinterGroup";
import { ProtectAMUpdates } from "./screens/ProtectAM/ProtectAMUpdates";
import { StaleCompanies } from "./screens/Company/StaleCompanies";
import { DisableOnboarding } from "./screens/DisableOnboarding";
import {
    CreateCompanyFromWorkbench,
    UpdateCompanyFromWorkbench,
} from "./screens/Company/WorkbenchCompany";
import { Streams } from "./screens/Company/Streams";
import urlJoin from "url-join";
import { ANALYZE_ROUTE } from "./utils/constants";
import { DisableTOS } from "./screens/DisableTOS";
import { ConfigureAnalyze } from "./screens/ConfigureAnalyze/ConfigureAnalyze";
import { DebugTools } from "./screens/Debug/DebugTools";

export const PATHS = {
    user: "/user",
    users: "/users",
    userGroup: "/user_group",
    userGroups: "/user_groups",
    newUserGroup: "/new_user_group",
    company: "/company",
    editCompany: "/edit_company",
    client: "/client",
    globalAdmins: "/admins",
    adminHome: "/",
    resetOnboarding: "/obscure_tools/reset_onboarding",
    staleCompanies: "/obscure_tools/stale_companies",
    disableOnboarding: "/obscure_tools/disable_onboarding",
    disableTOS: "/obscure_tools/disable_tos",
    licensePackage: "/license_package",
    licenses: "/licenses",
    feature: "/feature",
    product: "/product",
    license: "/license",
    domainRequests: "/domain_requests",
    sdk: "/sdk",
    printerGroups: "/printer_groups",
    companyPrinters: "/company_printers",
    printerGroup: "/printer_group",
    protectAMUpdates: "/protectam_updates",
    streams: "/streams",
    configureAnalyze: "/configure_analyze",
    debugTools: "/debug_tools",
};

export function editAccessOptionPath(optionId: number) {
    return `/company/access_option/${optionId}`;
}

// FIXME GC-81780: Candidate for consolidating between server + frontend
export function fullAnalyzeUrl(baseUrl: string) {
    // We have to join with an empty string here because `urlJoin` treats an IPv6 hostname as if it
    // contains a malformed protocol string and tries to fix the protocol if it's the first argument:
    // https://github.com/jfromaniello/url-join/blob/v5.0.0/lib/url-join.js#L19
    const analyzeUrl = new URL(ANALYZE_ROUTE, baseUrl);

    return urlJoin(config.REACT_APP_LOGIN_URL, `?return_to=${analyzeUrl}`, "&expose_auth");
}

interface IRoutesProps {
    location: NavLinkProps["location"];
    refreshTheme: () => void;
}

function isUrlPrefixValid(urlPrefix: string | undefined): boolean {
    const hostParts = window.location.hostname.split(".");
    // companyname.company.grabcad.com and company.grabcad.com are
    // both valid urls. We want to catch the case where a user tries
    // to access companyidotnohaveaccess.company.grabcad.com when they
    // are logged in to a different company. In this case, the hostname
    // will have 4 parts with the company name being the first part.
    if (urlPrefix && hostParts.length === 4) {
        return hostParts[0] === urlPrefix;
    }
    // if there is no URL prefix and user is trying to access a
    // company by URL prefix, then redirect to profile page.
    if (!urlPrefix && hostParts.length === 4) {
        return false;
    }
    return true;
}

export const Routes: FunctionComponent<IRoutesProps> = (props) => {
    const { location, refreshTheme } = props;
    const { role, companyId, urlPrefix } = useContext(UserContext);

    // Google analytics page view instrumentation
    useEffect(() => {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);
        refreshTheme();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (role !== Role.GlobalAdmin && (!companyId || !isUrlPrefixValid(urlPrefix))) {
        window.location.assign(`${config.REACT_APP_PROFILE_URL}`);
        return null;
    }
    switch (role) {
        case Role.GlobalAdmin:
            return (
                <Switch location={location}>
                    <Route path={PATHS.globalAdmins} component={Shell(EditGlobalAdmins)} />
                    <Route path={`${PATHS.company}/new`} component={Shell(CreateCompany)} />
                    <Route
                        path={`${PATHS.company}/wb`}
                        component={Shell(CreateCompanyFromWorkbench)}
                        exact
                    />
                    <Route
                        path={`${PATHS.company}/wb/:companyId`}
                        component={Shell(UpdateCompanyFromWorkbench)}
                    />
                    <Route
                        exact
                        path={`${PATHS.company}/:companyId`}
                        component={Shell(EditCompany)}
                    />
                    <Route
                        path={`${PATHS.license}/assign/:companyId`}
                        component={Shell(CreateLicense)}
                    />
                    <Route path={`${PATHS.license}/:id`} component={Shell(EditLicense)} />
                    <Route path={`${PATHS.client}/new`} component={Shell(CreateClient)} />
                    <Route path={`${PATHS.client}/:id`} component={Shell(EditClient)} />
                    <Route path={PATHS.client} component={Shell(ClientList)} />
                    <Route
                        path={`${PATHS.licensePackage}/new`}
                        component={Shell(CreateLicensePackage)}
                    />
                    <Route
                        path={`${PATHS.licensePackage}/:id`}
                        component={Shell(EditLicensePackage)}
                    />
                    <Route path={PATHS.licensePackage} component={Shell(LicensePackageList)} />
                    <Route path={PATHS.feature} component={Shell(FeatureList)} />
                    <Route path={`${PATHS.product}/new`} component={Shell(CreateProduct)} />
                    <Route path={`${PATHS.product}/:id`} component={Shell(EditProduct)} />
                    <Route path={PATHS.product} component={Shell(ProductList)} />
                    <Route path={PATHS.domainRequests} component={Shell(DomainRequestList)} />
                    <Route path={PATHS.streams} component={Shell(Streams)} />
                    <Route
                        path={`${PATHS.company}/:companyId/access_option/:id`}
                        component={Shell(EditCompanyAccessOption)}
                    />
                    <Route exact path="/" component={Shell(CompanyList)} />
                    <Route path={PATHS.staleCompanies} component={Shell(StaleCompanies)} />
                    <Route path={PATHS.debugTools} component={Shell(DebugTools)} />
                    <Redirect to="/" />;
                </Switch>
            );
        case Role.CompanyAdmin:
            return (
                <Switch location={location}>
                    <Route path={PATHS.newUserGroup} component={Shell(CreateUserGroup)} />
                    <Route path={PATHS.userGroups} component={Shell(CompanyUserGroups)} />
                    <Route path={PATHS.users} component={Shell(CompanyUsers)} />
                    <Route path={`${PATHS.userGroup}/:id`} component={Shell(EditUserGroup)} />
                    <Route path={`${PATHS.user}/:email`} component={Shell(EditUser)} />
                    <Route path={`${PATHS.editCompany}`} component={Shell(EditCompany)} />
                    <Route
                        path={`${PATHS.company}/access_option/:id`}
                        component={Shell(EditCompanyAccessOption)}
                    />
                    <Route path={`${PATHS.licenses}`} component={Shell(LicensesOverview)} />
                    <Route path={`${PATHS.product}/:id`} component={Shell(ProductLicenseDetails)} />
                    <Route path={`${PATHS.sdk}`} component={Shell(SdksOverview)} />
                    <Route path={PATHS.printerGroups} component={Shell(PrinterGroupsList)} />
                    <Route path={PATHS.companyPrinters} component={Shell(CompanyPrinters)} />
                    <Route exact path={PATHS.resetOnboarding} component={ResetOnboarding} />
                    <Route exact path={PATHS.disableOnboarding} component={DisableOnboarding} />
                    <Route exact path={PATHS.disableTOS} component={DisableTOS} />
                    <Route path={`${PATHS.printerGroup}/:id`} component={Shell(PrinterGroupList)} />
                    <Route path={PATHS.protectAMUpdates} component={Shell(ProtectAMUpdates)} />
                    <Route path={PATHS.configureAnalyze} component={Shell(ConfigureAnalyze)} />
                    <Route
                        exact
                        path="/"
                        component={Shell(CompanyAdminHomePage, { offWhiteBG: true })}
                    />
                    <Redirect to="/" />;
                </Switch>
            );
        case Role.User:
        default:
            // Non global or company admins cannot access Control.
            // Redirecting to https://login.grabcad.com/profile will show link to Shop/Analyze, or a helpful "Not Authorized" page.
            window.location.assign(
                `${config.REACT_APP_PROFILE_URL || "https://login.grabcad.com/profile"}`
            );
            return null;
    }
};
