export const ANALYZE_ROUTE = "/login/analyze/";

export const companyTypeOptions = [
    { key: "customer", text: "Customer", value: "customer" },
    { key: "reseller", text: "Reseller", value: "reseller" },
];

export const licesneStatusOptions = [
    { key: "all", text: "All", value: "all" },
    { key: "active", text: "Active", value: "active" },
    { key: "expired", text: "Expired", value: "expired" },
    { key: "expiring", text: "Expiring", value: "expiring" },
];
