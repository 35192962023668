import { UserContext } from "../../components/User/UserProvider";
import { IPrinter } from "grabcad-printers-api/node";
import styled, { Loader } from "grabcad-ui-elements";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { Role } from "../../graphql";
import { PATHS } from "../../Routes";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { NoPrinter } from "./NoPrinter";
import { PrinterDetailsModal } from "./PrinterDetailsModal";
import { useGetAllPrinters } from "./UseGetAllPrinters";
import { Printers } from "./Printers";
import { PrinterGateways } from "../Gateway/PrinterGateways";

const StyledCompanyPrinters = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
    margin: 0 -25px; // negative margin + padding to prevent cards' dropshadow :hover style from being cropped
    padding: 0 25px;
    min-height: 100%;
    header: {
        flex: 0 0 auto;
    }
`;

export const CompanyPrinters = () => {
    const { t } = useContext(ApplicationContext);
    const history = useHistory();
    const { role, companyId } = useContext(UserContext);
    const { loadingPrinters, setLoadingPrinters, printers, errorOccurred, errorMessage } =
        useGetAllPrinters();
    const [printerModalOpen, setPrinterModalOpen] = useState(false);
    const [modalPrinter, setModalPrinter] = useState<IPrinter | null>(null);

    const openModal = (id: string) => {
        const printer = printers.find((p) => p.getId() === id);
        if (printer) {
            setModalPrinter(printer);
            setPrinterModalOpen(true);
        }
    };

    useEffect(() => {
        // Make sure to send printers tracking event once and only once the data is actually loaded
        if (!loadingPrinters) {
            ReactGA.event({
                category: "Print Manager Company Printers",
                action: "Queried Company Printers",
                label: `Company ${companyId}`,
                value: printers.length,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingPrinters]);

    if (role !== Role.CompanyAdmin) {
        history.push(PATHS.adminHome);
        return <></>;
    }

    if (loadingPrinters) {
        return <Loader active />;
    }

    const content =
        !printers.length || errorOccurred ? (
            <>
                <NoPrinter errorOccurred={errorOccurred} errorMessage={errorMessage} />
                <PrinterGateways />
            </>
        ) : (
            <>
                <Printers printers={printers} onClick={(id) => openModal(id)} />
                <PrinterGateways />
            </>
        );

    return (
        <>
            <StyledCompanyPrinters>
                <header>
                    <Breadcrumbs sections={[{ label: t("printers.breadcrumb") }]} />
                    <h2 className="page-header">{t("printers.header")}</h2>
                </header>
                {content}
            </StyledCompanyPrinters>
            <PrinterDetailsModal
                printer={modalPrinter}
                isOpen={printerModalOpen}
                onClose={() => setPrinterModalOpen(false)}
                history={history}
                onUpdate={() => setLoadingPrinters(true)}
            />
        </>
    );
};
