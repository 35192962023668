import gql from "graphql-tag";
import { Company } from "../../types";

export const LIST_COMPANIES = gql`
    query listCompanies(
        $skip: Int
        $take: Int
        $search: String
        $isExternalCompany: Boolean
        $companyType: String
        $hasActiveLicenses: Boolean
        $productId: Int
        $licensePackageId: Int
    ) {
        listCompanies(
            pageSpec: { skip: $skip, take: $take, search: $search }
            isExternalCompany: $isExternalCompany
            companyType: $companyType
            hasActiveLicenses: $hasActiveLicenses
            productId: $productId
            licensePackageId: $licensePackageId
        ) {
            totalCount
            companies {
                id
                name
                urlPrefix
                tosState
                admins {
                    email
                }
                resellerInfo {
                    accountNumber
                    contactPerson
                    email
                    name
                    phoneNumber
                }
                customerInfo {
                    accountNumber
                    email
                    name
                    phoneNumber
                }
                licenses {
                    id
                    state
                    endDate
                    seatsUsed
                    package {
                        name
                        companyWide
                    }
                }
            }
        }
    }
`;

export interface ListCompaniesVariables {
    skip?: number;
    take?: number;
    search?: string;
    companyType?: string;
    isExternalCompany?: boolean;
    hasActiveLicenses?: boolean;
    productId?: number;
    licensePackageId?: number;
}

export interface ListCompaniesQueryResult {
    listCompanies: {
        companies: Company[];
        totalCount: number;
    };
}
