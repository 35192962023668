import { FunctionComponent, ComponentClass, useContext } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { MenuItemProps } from "semantic-ui-react";
import styled, {
    Dropdown,
    Image,
    Menu,
    MenuItem,
    Header,
    StyledJoyride,
    StyledModal,
    Button,
} from "grabcad-ui-elements";
import { UserContext } from "../components/User/UserProvider";
import { PATHS } from "../Routes";
import { ErrorBoundary } from "./ErrorBoundary";
import { config } from "../config";
import { workbenchifyComanyId, Role, useUserGroupsQuery, useCompanyAsAdminQuery } from "../graphql";
import { ApplicationContext } from "../components/ApplicationProvider";
import CompaniesSvg from "../images/sidebar/companies.svg";
import ClientsSvg from "../images/sidebar/clients.svg";
import GlobalAdminsSvg from "../images/sidebar/global_admins.svg";
import FeaturesSvg from "../images/sidebar/features.svg";
import ProductsSvg from "../images/sidebar/products.svg";
import LicensePackagesSvg from "../images/sidebar/license_packages.svg";
import MachineApiRuntime from "../images/sidebar/machine_api_runtime.svg";
import EditCompanySvg from "../images/sidebar/edit_company.svg";
import UserGroupsSvg from "../images/sidebar/user_groups.svg";
import PrinterGroupsSvg from "../images/sidebar/printer_groups.svg";
import PrintersSvg from "../images/sidebar/printers.svg";
import ProtectAMUpdatesSVG from "../images/sidebar/protectam_updates.svg";
import StratasysLogo from "../images/stratasys-with-logomark.svg";
import ReportsSVG from "../images/reports.svg";
import ScheduleSVG from "../images/schedule.svg";
import { useOnboarding } from "../utils/hooks/onboarding";
import useActiveShopCompanyLicenses from "../graphql/Queries/Licenses/GetActiveShopCompanyLicenses";
import { NAVBAR_HEIGHT_PX, TopNavbarWrapper } from "./TopNavbarWrapper";
import { StyledModalActions, StyledModalContent, StyledModalHeader } from "./Printer/StyledModal";
import { useAcceptTOS } from "../graphql/Mutations/Companies";
import { useCompanyLicensesQuery } from "../graphql/Queries/Licenses/GetCompanyLicenses";
import AnalyzeSvg from "../images/sidebar/logo-Analyze-A.svg";

export const USER_GROUPS = "user-groups";
export const USERS = "users";
const CLOUD_BASE_URL = "https://print.grabcad.com/print/";

const Application = styled.div`
    flex: 1 1 1px;
    display: flex;
    height: calc(100vh - ${NAVBAR_HEIGHT_PX});
    overflow-y: auto;
    h1 {
        margin-top: 10px;
    }

    // This matches Shop (defined in ShopWrapper, which is functionally equivalent to this Application wrapper)
    h2.page-header {
        font-weight: bold;
        margin-bottom: 0;
        margin-top: 0;
        // The following is in Shop but simply messes up the License pages in company, so disabling
        // flex: 1 1 0%;
    }
`;

const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const Sidebar = styled.div`
    width: 240px;
    min-width: 240px;
    padding: 15px 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .ui.secondary.menu {
        flex: 1 0 auto;
    }

    background: linear-gradient(
        90deg,
        rgb(244, 245, 247) 0%,
        rgb(244, 245, 247) 97%,
        rgb(224, 224, 228) 100%
    );

    ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Make scrollbar invisible */
    }

    .stratasys-logo {
        display: inline-block;
        img {
            width: 99px;
            margin: 4px 9px;
        }
    }
`;
Sidebar.displayName = "Sidebar";

const AdminMenu = styled(Menu)`
    &.ui.secondary.menu {
        margin-left: auto;
        margin-right: auto;
        margin-top: 3em;
        text-align: left;
        display: flex;
        flex-direction: column;

        .main-items {
            flex: 1 0 auto;

            .item {
                padding-top: 0;
                padding-bottom: 0;

                .image {
                    margin-right: 12px;
                    height: 1.35em;
                    width: 1.35em;
                }
            }
        }
    }
`;
AdminMenu.displayName = "AdminMenu";

const ProfileDropdown = styled(Dropdown)`
    max-width: 100%;

    i.down.icon {
        padding: 0 10px;
        position: relative;
        top: 1px;
    }

    h2 {
        margin: 0 0 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
    }
`;
ProfileDropdown.displayName = "ProfileDropdown";

const StyledTOSModal = styled(StyledModal)`
    background: #0000 !important;

    & > .actions {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
`;

export interface IScreenProps {
    history: RouteComponentProps["history"];
    location: RouteComponentProps["location"];
    match: RouteComponentProps["match"];
}

interface IScreenHolderProps {
    offWhiteBG?: boolean;
}

const logout = () => {
    let currentUrl = window.location.href;
    window.location.assign(`${config.REACT_APP_LOGOUT_URL}?return_to=${currentUrl}`);
};

const updatePassword = () => {
    let currentUrl = window.location.href;
    window.location.assign(`${config.REACT_APP_UPDATE_PASSWORD_URL}?return_to=${currentUrl}`);
};

type SidebarLinkProps = { pathname?: string; action?: () => void } & MenuItemProps;

const SidebarLink = ({ pathname, action, ...props }: SidebarLinkProps) => {
    const { push } = useHistory();
    const location = useLocation();

    // This might seem a bit odd, but the problem is that licenses live under /product and how activity is determined right now is not optimal. So do user_groups and printer_groups
    const active: boolean =
        (pathname === "/licenses" && location.pathname.includes("/product/")) ||
        (pathname === "/user_groups" && location.pathname.includes("user_group")) ||
        (pathname === "/printer_groups" && location.pathname.includes("printer_group")) ||
        location.pathname === pathname;

    return (
        <MenuItem
            active={active}
            onClick={pathname ? () => push(pathname) : undefined}
            {...props}
        />
    );
};

const Shell = (
    ScreenComponent: FunctionComponent<IScreenProps> | ComponentClass<IScreenProps>,
    screenHolderProps?: IScreenHolderProps
) => {
    const shell: FunctionComponent<IScreenProps> = (props: IScreenProps) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        const { role } = useContext(UserContext);
        const { enabledFeatures, companyLicenses } = useCompanyLicensesQuery();
        const { company } = useCompanyAsAdminQuery();
        const onboarding = useOnboarding();

        const { t } = useContext(ApplicationContext);
        const { data: licensesData, loading: loadingShopLicenses } = useActiveShopCompanyLicenses();
        const { userGroups } = useUserGroupsQuery();
        const [acceptTOS] = useAcceptTOS();
        /* eslint-enable react-hooks/rules-of-hooks */

        if (!companyLicenses) {
            // Wait for licenses before rendering anything. This is largely a convenience for cypress, which
            // otherwise doesn't wait for licenses to load before clicking in the TopNavbar for example.
            // It's also a slightly cleaner UX, since licensed links won't appear a split seconds after the
            // rest of the page renders.
            return null;
        }

        let menuItems: JSX.Element[] = [];

        if (role === Role.GlobalAdmin) {
            menuItems.push(
                <SidebarLink id="qa-sidebar-companies" key="home" pathname="/">
                    <Image src={CompaniesSvg} />
                    Companies
                </SidebarLink>,
                <SidebarLink id="qa-sidebar-clients" key="clients" pathname={PATHS.client}>
                    <Image src={ClientsSvg} />
                    Clients
                </SidebarLink>,
                <SidebarLink
                    id="qa-sidebar-globalAdministrators"
                    key="admins"
                    pathname={PATHS.globalAdmins}
                >
                    <Image src={GlobalAdminsSvg} />
                    Global Administrators
                </SidebarLink>,
                <SidebarLink id="qa-sidebar-features" key="features" pathname={PATHS.feature}>
                    <Image src={FeaturesSvg} />
                    Features
                </SidebarLink>,
                <SidebarLink id="qa-sidebar-products" key="products" pathname={PATHS.product}>
                    <Image src={ProductsSvg} />
                    Products
                </SidebarLink>,
                <SidebarLink
                    id="qa-sidebar-licensePackages"
                    key="licensePackages"
                    pathname={PATHS.licensePackage}
                >
                    <Image src={LicensePackagesSvg} />
                    License Packages
                </SidebarLink>,
                <SidebarLink id="qa-sidebar-streams" key="streams" pathname={PATHS.streams}>
                    <Image src={MachineApiRuntime} />
                    Streams
                </SidebarLink>,
                <SidebarLink
                    id="qa-sidebar-domainRequests"
                    key="domainRequests"
                    pathname={PATHS.domainRequests}
                >
                    Domain Requests
                </SidebarLink>
            );
        }

        if (role === Role.CompanyAdmin) {
            menuItems.push(
                <SidebarLink id="qa-sidebar-admin-home" key="admin_home" pathname={PATHS.adminHome}>
                    <Image src={CompaniesSvg} />
                    {t("sidebar.admin_home")}
                </SidebarLink>,
                <SidebarLink
                    id="qa-sidebar-editCompany"
                    key="edit_company"
                    pathname={PATHS.editCompany}
                >
                    <Image src={EditCompanySvg} />
                    {t("sidebar.edit_company")}
                </SidebarLink>,
                <SidebarLink
                    id="qa-sidebar-users"
                    className="users-menu-item"
                    key="users"
                    pathname={PATHS.users}
                >
                    <Image src={ClientsSvg} />
                    {t("sidebar.users")}
                </SidebarLink>,
                <SidebarLink
                    id="qa-sidebar-userGroups"
                    className="groups-menu-item"
                    key="groups"
                    pathname={PATHS.userGroups}
                >
                    <Image src={UserGroupsSvg} />
                    {t("sidebar.user_groups")}
                </SidebarLink>,
                <SidebarLink
                    id="qa-sidebar-licenses"
                    className="groups-menu-item"
                    key="licenses"
                    pathname={PATHS.licenses}
                >
                    <Image src={ProductsSvg} />
                    {t("sidebar.licenses")}
                </SidebarLink>
            );

            menuItems.push(
                <SidebarLink
                    id="qa-sidebar-company-printers"
                    key="company_printers"
                    className="groups-menu-item"
                    pathname={PATHS.companyPrinters}
                >
                    <Image src={PrintersSvg} />
                    {t("sidebar.company_printers")}
                </SidebarLink>
            );

            menuItems.push(
                <SidebarLink
                    id="qa-sidebar-printer-groups"
                    key="printer_groups"
                    className="groups-menu-item"
                    pathname={PATHS.printerGroups}
                >
                    <Image src={PrinterGroupsSvg} />
                    {t("sidebar.printer_groups")}
                </SidebarLink>
            );

            if (!onboarding.isCompleted(USER_GROUPS)) {
                menuItems.push(
                    <StyledJoyride
                        steps={[
                            {
                                target: ".groups-menu-item",
                                title: t("onboarding.groups.title"),
                                content: t("onboarding.groups.content"),
                                placement: "right-start",
                            },
                        ]}
                        dismissCopy={t("onboarding.dismiss")}
                        callback={({ lifecycle }) => {
                            if (lifecycle === "complete") {
                                void onboarding.setCompleted(USER_GROUPS);
                            }
                        }}
                        key="user-groups-joyride"
                    />
                );
            } else if (!onboarding.isCompleted(USERS) && userGroups.length) {
                // This onboarding step is only shown once at least one UserGroup is created
                menuItems.push(
                    <StyledJoyride
                        steps={[
                            {
                                target: ".users-menu-item",
                                title: t("onboarding.users.title"),
                                content: t("onboarding.users.content"),
                                placement: "right-start",
                            },
                        ]}
                        dismissCopy={t("onboarding.dismiss")}
                        callback={({ lifecycle }) => {
                            if (lifecycle === "complete") {
                                void onboarding.setCompleted(USERS);
                            }
                        }}
                        key="users-joyride"
                    />
                );
            }
        }

        if (enabledFeatures.stigAccess) {
            menuItems.push(
                <SidebarLink
                    id="qa-sidebar-protectam-updates"
                    key="protectAMUpdates"
                    className="bottomMenu"
                    pathname={PATHS.protectAMUpdates}
                >
                    <Image src={ProtectAMUpdatesSVG} />
                    {t("sidebar.protect_am_updates")}
                </SidebarLink>
            );
        }

        const getOpenShopAction = () => {
            if (
                (role === Role.CompanyAdmin || role === Role.GlobalAdmin) &&
                !loadingShopLicenses &&
                !!licensesData?.getActiveShopCompanyLicenses.totalCount
            ) {
                return () => {
                    location.href = company?.urlPrefix
                        ? `https://${company?.urlPrefix}.${config.SHOP_HOST}`
                        : `https://${config.SHOP_HOST}`;
                };
            }
            return () => {
                window.open("https://grabcad.com/shop", "_blank");
            };
        };

        if (role === Role.CompanyAdmin) {
            if (company?.id) {
                const wbCompanyId = workbenchifyComanyId(
                    config.REACT_APP_SPOOFED_COMPANY_ID || company?.id
                );

                if (enabledFeatures.analyzeAccess) {
                    menuItems.push(
                        <SidebarLink
                            id="qa-sidebar-configure-analyze"
                            key="configure_analyze"
                            className="groups-menu-item"
                            pathname={PATHS.configureAnalyze}
                        >
                            <Image src={AnalyzeSvg} style={{ width: "20px", height: "20px" }} />
                            {t("sidebar.configure_analyze")}
                        </SidebarLink>
                    );
                }

                menuItems.push(
                    <SidebarLink
                        id="qa-sidebar-launch-reports"
                        active={false}
                        key="reports"
                        onClick={() =>
                            window.open(`${CLOUD_BASE_URL}${wbCompanyId}/reports/materials_usage`)
                        }
                    >
                        <Image src={ReportsSVG} />
                        {t("sidebar.reports")}
                        <i style={{ marginLeft: 4 }} className="external alternate icon"></i>
                    </SidebarLink>,
                    <SidebarLink
                        id="qa-sidebar-launch-schedule"
                        key="schedule"
                        active={false}
                        onClick={() => window.open(`${CLOUD_BASE_URL}${wbCompanyId}/schedule`)}
                    >
                        <Image src={ScheduleSVG} />
                        {t("sidebar.schedule")}
                        <i style={{ marginLeft: 4 }} className="external alternate icon"></i>
                    </SidebarLink>
                );
            }
        }

        return (
            <AppWrapper>
                <TopNavbarWrapper
                    logout={logout}
                    updatePassword={updatePassword}
                    openShop={getOpenShopAction()}
                />
                <Application>
                    <ErrorBoundary>
                        <Sidebar>
                            <div style={{ padding: "1rem 1rem 0" }}>
                                <Header id="qa-sidebar-profile-companyName" as="h2">
                                    {company?.name || "GrabCAD"}
                                </Header>
                            </div>
                            <AdminMenu vertical secondary size="huge" fluid>
                                <div className="main-items">{menuItems}</div>
                            </AdminMenu>
                            <div>
                                <a className="stratasys-logo" href="https://www.stratasys.com/">
                                    <img src={StratasysLogo} alt="Stratasys" />
                                </a>
                            </div>
                        </Sidebar>
                        <div
                            style={{
                                position: "relative",
                                flexGrow: 1,
                                maxHeight: "100%",
                                // padding here matches ShopWrapper padding in Shop frontend
                                padding: "26px 15px 15px 15px",
                                overflow: "auto",
                                overflowX: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                background: screenHolderProps?.offWhiteBG ? "#f5f6f8" : undefined,
                            }}
                        >
                            <ScreenComponent {...props} />
                        </div>
                        <StyledTOSModal
                            centered
                            size="mini"
                            open={
                                role === Role.CompanyAdmin &&
                                company &&
                                company.tosState !== "ACCEPTED"
                            }
                        >
                            <StyledModalHeader content={t("tos.header")} />
                            <StyledModalContent>
                                <p>
                                    {`${t("tos.by_clicking_on")} "${t("tos.accept")}", ${t(
                                        "tos.you_confirm_that"
                                    )} `}
                                    <a
                                        href="https://grabcad.com/terms"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {t("tos.terms_of_service")}
                                    </a>
                                    {", "}
                                    <a
                                        href="https://grabcad.com/software_terms"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {t("tos.software_terms")}
                                    </a>
                                    {` ${t("tos.and")} `}
                                    <a
                                        href="https://grabcad.com/privacy_policy"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {t("tos.privacy_policy")}
                                    </a>
                                    {` ${t("tos.and_that_you")}`}
                                </p>
                            </StyledModalContent>
                            <StyledModalActions>
                                <Button secondary onClick={logout}>
                                    {t("tos.logout")}
                                </Button>
                                <Button
                                    id="qa-accept-tos"
                                    primary
                                    onClick={() => {
                                        void acceptTOS();
                                    }}
                                >
                                    {t("tos.accept")}
                                </Button>
                            </StyledModalActions>
                        </StyledTOSModal>
                    </ErrorBoundary>
                </Application>
            </AppWrapper>
        );
    };
    shell.displayName = `Shell(${ScreenComponent.displayName})`;
    return shell;
};

export { Shell };
