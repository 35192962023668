import React, { useContext, useEffect, useState, FormEvent } from "react";
import { Button, Form, FormField, Input } from "grabcad-ui-elements";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { GatewayRegionSelector } from "./GatewayRegionSelector";
import { Notifier } from "../../view/Notifier";
import _ from "lodash";
import {
    StyledMiniModal,
    StyledModalHeader,
    StyledCloseButton,
    StyledModalContent,
    StyledModalActions,
    StyledActions,
} from "../Printer/StyledModal";
import classnames from "classnames";
import { createGateway, requestGatewayInstaller } from "../../zeus-client/gatewaysApis";

export const GROUP_NAME_MAX_LENGTH = 800;
const regex = /^[0-9a-zA-Z_.,@/:#-]+$/;
const spaceRegex = /^\S*$/;

interface Props {
    open: boolean;
    onClose: () => void;
    setCheckIfPollingTrue: (setProperty: boolean) => void;
    setNewThingName: (setThingName: string) => void;
    onSuccessfullRequest: () => void;
    printerSerial?: string;
}

/**
 * Modal for creating Gateway Installer.
 */
const GatewayModal: React.FC<Props> = ({
    open,
    onClose,
    onSuccessfullRequest,
    setCheckIfPollingTrue,
    setNewThingName,
    printerSerial,
}: Props) => {
    const { t } = useContext(ApplicationContext);
    const [siteName, setSiteName] = useState<string>("");
    const [region, setRegion] = useState<string>("");
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<{ [x: string]: string }>({});
    const [nameInvalidReason, setNameInvalidReason] = useState<string | undefined>();

    const getSiteNameInvalidReason = (newName: string): string => {
        setNameInvalidReason("");
        const trimmedName = newName.trim();

        if (!trimmedName.length) {
            return t("gateway_modal.validation.site_name_required");
        }
        if (trimmedName.length > GROUP_NAME_MAX_LENGTH) {
            return t("gateway_modal.validation.site_name_too_long", {
                maxLength: GROUP_NAME_MAX_LENGTH,
            });
        }
        if (!spaceRegex.test(trimmedName)) {
            return t("gateway_modal.validation.site_name_space_not_allowed");
        }
        if (!regex.test(trimmedName)) {
            return t("gateway_modal.validation.site_name_invalid_format");
        }
        return "";
    };

    const validateRegion = (name: string): string => {
        const trimmedName = name.trim();

        if (!trimmedName.length) {
            return t("gateway_modal.validation.region_required");
        }
        return "";
    };

    useEffect(() => {
        // validate site name and region fields
        if (nameInvalidReason) {
            setFormErrors({
                message: nameInvalidReason,
            });
        } else {
            setFormErrors({});
        }
    }, [siteName, nameInvalidReason]);

    /**
     * Handle form cancel (modal closed without form submission) event.
     */
    const onCancel = () => {
        // clear form state
        clearForm();
        // trigger passed-in close handler callback
        onClose();
    };

    const clearForm = () => {
        setSubmitClicked(false);
        setSiteName("");
        setRegion("");
        setNameInvalidReason("");
        setFormErrors({});
    };

    const triggerInstaller = async (coreDeviceThingName: string) => {
        if (!!coreDeviceThingName) {
            try {
                // Request the gateway installer for the specified core device with printerSerial optional.
                // Printer serial number will be used to associate the gateway with correct company
                await requestGatewayInstaller(coreDeviceThingName, printerSerial);
                setNewThingName(coreDeviceThingName);
                Notifier.success(t("gateway_modal.success_message"));
                setSubmitClicked(false);
                clearForm();
                onSuccessfullRequest();
                setCheckIfPollingTrue(true);
            } catch {
                Notifier.fail(t("gateways.gateway_request_error"));
            }
        }
    };

    /**
     * Form submit handler.
     */
    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitClicked(true);
        const reason = getSiteNameInvalidReason(siteName) || validateRegion(region);
        setNameInvalidReason(reason);
        if (reason) {
            setSubmitClicked(false);
            return; // Exit early if there are validation errors
        }
        try {
            // Call Create gateway and retrieve the core device name from the response.
            // Printer serial number will be used to associate the gateway with correct company
            const response = await createGateway(siteName, region, printerSerial);
            // If a core device name is returned in the response, trigger the installer for the gateway.
            if (response?.coreDeviceThingName) {
                setSubmitClicked(true);
                // Request the gateway installer for the specified core device with printerSerial optional.
                await triggerInstaller(response?.coreDeviceThingName);
            }
            setSubmitClicked(false);
        } catch {
            setSubmitClicked(false);
            Notifier.fail(t("gateways.installer_creation_failed_msg"));
        }
    };

    return (
        <StyledMiniModal basic open={open} onClose={onCancel}>
            <StyledModalHeader>
                <h3>{t("gateway_modal.header")}</h3>
                <StyledCloseButton
                    className={`qa-printerGroups-PrinterGroupModal-cancel`}
                    name="remove"
                    onClick={onCancel}
                    data-testid="gateway-modal-close-button"
                />
            </StyledModalHeader>
            <Form onSubmit={onSubmit}>
                <StyledModalContent>
                    <FormField key="siteName" className="required">
                        <label>{t("gateway_modal.site_name")}</label>
                        <Input
                            role="site-name-input"
                            className={classnames(
                                `qa-gatewayModal-site-name-input`,
                                "qa-printerGroupName"
                            )}
                            value={siteName}
                            onChange={(e) => {
                                if (nameInvalidReason) {
                                    setNameInvalidReason("");
                                }
                                setSiteName(e.currentTarget.value);
                            }}
                            data-testid="gateway-modal-site-name-input"
                        />
                    </FormField>
                    <FormField></FormField>
                    <FormField key="region">
                        <GatewayRegionSelector
                            id="region_selector"
                            disabled={false}
                            selectedRegion={region}
                            onChange={(
                                DEvent: React.SyntheticEvent<HTMLElement>,
                                regionValue: string
                            ) => {
                                setRegion(regionValue);
                            }}
                            data-testid="gateway-modal-region-selector"
                        />
                    </FormField>
                    {formErrors.message && (
                        <p className="ui negative message qa-formValidationMessage">
                            {formErrors.message}
                        </p>
                    )}
                </StyledModalContent>
                <StyledModalActions>
                    <StyledActions>
                        <FormField>
                            <Button
                                role="qa-gatewayModal-save"
                                className="qa-gatewayModal-save primary right floated "
                                type="submit"
                                disabled={submitClicked}
                                loading={submitClicked}
                                data-testid="gateway-modal-save-button"
                            >
                                {t("gateway_modal.create_gateway_installer")}
                            </Button>
                        </FormField>
                    </StyledActions>
                </StyledModalActions>
            </Form>
        </StyledMiniModal>
    );
};

export default GatewayModal;
