import gql from "graphql-tag";

export const LicenseFragments = {
    // These are mostly the "editable" fields without the 'externalId':
    licenseFields: gql`
        fragment licenseFields on License {
            id
            companyId
            maxUsers
            seatsUsed
            quantity
            orderDate
            startDate
            endDate
            orderId
            partNumber
            orderLineNumber
            comments
            resellerSale
            poNumber
            state
            machineEntitlements {
                id
                serial
                technology
                model
            }
            materialEntitlements {
                id
                technology
                name
            }
        }
    `,
    additionalLicenseFields: gql`
        fragment additionalLicenseFields on License {
            externalId
        }
    `,
};
