import { FunctionComponent, FormEvent, useState } from "react";
import { match, Redirect } from "react-router-dom";
import classnames from "classnames";
import * as _ from "lodash";
import { Query } from "@apollo/client/react/components";
import { QueryResult, PureQueryOptions, useMutation, useQuery } from "@apollo/client";
import { Button, Form, FormField, Input, Checkbox, TextArea, Dropdown } from "grabcad-ui-elements";
import { IScreenProps } from "../Screen";
import {
    LicensePackageData,
    CREATE_PACKAGE,
    UPDATE_PACKAGE,
    LicenseAttribute,
} from "../../graphql";
import { Notifier } from "../../view/Notifier";
import gql from "graphql-tag";
import { PATHS } from "../../Routes";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { deepEquals } from "../../utils/form";
import { ProductDropdown } from "./ProductDropdown";
import { Properties } from "csstype";
import { LIST_PRODUCTS_WITH_COMPANIES_AND_PACKAGES } from "../../graphql/Queries/Products/ListProducts";
import { ProductFragments } from "../../graphql/Fragments/Product";
import LicenseCompanyList from "./LicenseCompanyList";

const GET_PACKAGE = gql`
    query licensePackage($id: Int!) {
        licensePackage(id: $id) {
            id
            name
            description
            disabled
            product {
                ...productFields
            }
            partNumber
            key
            maxUsers
            unlimitedMaxUsers
            companyWide
            expiringMsgDisplayDaysLeft
            duration
            attributes {
                id
                name
            }
        }
    }
    ${ProductFragments.productFields}
`;

const GET_ATTRIBUTES = gql`
    query listLicenseAttributes {
        listLicenseAttributes {
            id
            name
        }
    }
`;

const fieldRequiredCopy = "This field is required.";

export const CreateLicensePackage: FunctionComponent<IScreenProps> = (props) => {
    return (
        <>
            <Breadcrumbs
                sections={[
                    { label: "License Packages", to: PATHS.licensePackage },
                    { label: "New License Package" },
                ]}
            />
            <LicensePackageForm mutation={CREATE_PACKAGE} {...props} />
        </>
    );
};

interface IEditLicensePackageProps extends IScreenProps {
    match: match<{ id?: string }>;
}

export const EditLicensePackage: FunctionComponent<IEditLicensePackageProps> = (props) => {
    const licensePackageId: number = parseInt((props.match.params as any).id, 10);
    const { loading, error, data, refetch } = useQuery(GET_PACKAGE, {
        variables: { id: licensePackageId },
    });

    if (loading) {
        return <div className="ui loader active" />;
    }
    if (error) {
        Notifier.error(error);
        return <Redirect to="/" />;
    }
    const licensePackage = { ...data.licensePackage };

    return (
        <>
            <Breadcrumbs
                sections={[
                    { label: "License Packages", to: PATHS.licensePackage },
                    { label: licensePackage.name },
                ]}
            />
            <LicensePackageForm
                licensePackage={licensePackage}
                mutation={UPDATE_PACKAGE}
                refetch={refetch}
                {...props}
            />
            <LicenseCompanyList packageId={licensePackageId} {...props} />
        </>
    );
};

interface LicensePackageFormField {
    id: keyof LicensePackageData;
    label: string;
    sublabel?: string;
    required?: boolean;
    invalidator?: (string: any) => string | undefined;
    inputComponent?: string;
    type?: string;
    style?: Properties<string | number>;
}

interface ILicensePackageFormProps extends IScreenProps {
    mutation: any;
    licensePackage?: LicensePackageData;
    refetch?: any;
    refetchQueries?: (string | PureQueryOptions)[];
}

const licensePackageNameField: LicensePackageFormField = {
    id: "name",
    label: "License Package Name",
    required: true,
    style: {
        width: 486,
        marginRight: 30,
    },
};
const partNumberField: LicensePackageFormField = {
    id: "partNumber",
    label: "Part Number",
    required: true,
    style: {
        width: 264,
    },
};
const packageKeyField: LicensePackageFormField = {
    id: "key",
    label: "Package Key",
    sublabel: "Unique Identifier",
    required: true,
    style: {
        width: 264,
    },
};
const descriptionField: LicensePackageFormField = {
    id: "description",
    label: "License Package Description",
    required: true,
    inputComponent: "textArea",
};
export const LicensePackageForm: React.FC<ILicensePackageFormProps> = ({
    mutation,
    licensePackage,
    refetch,
    refetchQueries,
    history,
}) => {
    const [licensePackageState, setLicensePackage] = useState<LicensePackageData>(
        licensePackage || ({} as LicensePackageData)
    );
    const [submitClicked, setSubmitClicked] = useState(false);

    const creatingPackage = () => mutation === CREATE_PACKAGE;

    const validateNumUsers = () =>
        licensePackageState.maxUsers || licensePackageState.unlimitedMaxUsers === true;

    const validateProduct = () =>
        creatingPackage() ? !!licensePackageState.productId : !!licensePackage?.product;

    const validateDuration = () =>
        licensePackageState.duration === null ||
        licensePackageState.duration === "yearly" ||
        licensePackageState.duration === "monthly";

    const validateAll = () => {
        const simpleFields = [
            licensePackageNameField,
            partNumberField,
            packageKeyField,
            descriptionField,
        ] as any;
        const simpleFieldsValid = !simpleFields.some(
            (field: LicensePackageFormField) => field.required && !licensePackageState[field.id]
        );
        return simpleFieldsValid && validateNumUsers() && validateProduct() && validateDuration();
    };

    const updateField = (
        id: keyof LicensePackageData,
        event: FormEvent<HTMLInputElement>,
        isNumber?: boolean
    ) => {
        let { value } = event.target as HTMLInputElement;
        let newLicensePackage = { ...licensePackageState };
        (newLicensePackage[id] as string | number) = isNumber ? Number(value) : value;
        setLicensePackage(newLicensePackage);
    };

    const [updateMutation, { loading }] = useMutation(mutation, {
        refetchQueries: [
            ...(refetchQueries ?? []),
            { query: LIST_PRODUCTS_WITH_COMPANIES_AND_PACKAGES },
        ],
        onCompleted: () => {
            setSubmitClicked(false);
            refetch?.();
            if (creatingPackage()) {
                Notifier.success(`Successfully created license package.`);
                history.push(PATHS.licensePackage);
            } else {
                Notifier.success(`Successfully updated license package.`);
            }
        },
        onError: (error) => Notifier.error(error),
    });

    const inputComponents: { [id: string]: any } = {
        input: Input,
        textArea: TextArea,
    };
    const getSimpleField = (field: LicensePackageFormField) => {
        const InputClass = inputComponents[field.inputComponent || "input"];
        const invalid: boolean =
            submitClicked && !!(field.required && !licensePackageState[field.id]);
        return (
            <FormField key={field.id} disabled={loading}>
                <label>
                    {field.label}
                    {field.required && <span style={{ color: "red" }}>&nbsp;*</span>}
                </label>
                <InputClass
                    id={`qa-licensePackage-field_${field.id}`}
                    className={`qa-licensePackage-field ${classnames({ error: invalid })}`}
                    autoComplete={"off"}
                    value={(licensePackageState[field.id] as string) || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField(field.id, e)}
                    type={field.type}
                    style={field.style}
                />
                {field.sublabel && (
                    <p style={{ fontStyle: "italic", fontSize: 12, marginTop: 2 }}>
                        {field.sublabel}
                    </p>
                )}
                {invalid && (
                    <p style={field.style as React.CSSProperties} className="ui negative message">
                        {fieldRequiredCopy}
                    </p>
                )}
            </FormField>
        );
    };

    const getAttributeCheckbox = (attribute: LicenseAttribute) => {
        const paddingLeft = attribute.id === 1 ? 0 : 18;
        return (
            <Checkbox
                id={`qa-licensePackage-attributes-${attribute.id}`}
                key={attribute.id}
                label={attribute.name}
                defaultChecked={licensePackageState.attributes?.some(
                    (att) => att.id === attribute.id
                )}
                style={{ paddingLeft: paddingLeft }}
                onChange={() => {
                    let newLicensePackage = { ...licensePackageState };
                    newLicensePackage.attributes = newLicensePackage.attributes
                        ? _.find(newLicensePackage.attributes, attribute)
                            ? newLicensePackage.attributes.filter(
                                  (attr) => attr.id !== attribute.id
                              )
                            : [...newLicensePackage.attributes, attribute]
                        : [attribute];
                    setLicensePackage(newLicensePackage);
                }}
            />
        );
    };

    licensePackageState.expiringMsgDisplayDaysLeft = 30;

    const isCreate = creatingPackage();
    const isDirty = !deepEquals(licensePackage, licensePackageState);
    const isProductValid = validateProduct();
    const isDurationValid = validateDuration();
    const isValid = validateAll();
    const isNumUsersValid = validateNumUsers();
    const numUsersColor = submitClicked && !isNumUsersValid ? "red" : "#c1c1c1";
    return (
        <Form
            style={{ width: 780 }}
            onSubmit={(event) => {
                event.preventDefault();
                setSubmitClicked(true);
                if (isValid && !loading) {
                    void updateMutation({
                        variables: {
                            ...licensePackageState,
                            attributeIds: licensePackageState.attributes?.map((attr) => attr.id),
                        },
                    });
                }
            }}
        >
            <div style={{ display: "flex" }}>
                {getSimpleField(licensePackageNameField)}
                {getSimpleField(partNumberField)}
            </div>
            <div style={{ display: "flex" }}>
                <FormField key="productName" disabled={loading}>
                    <ProductDropdown
                        productId={
                            !!licensePackageState.product
                                ? licensePackageState.product.id
                                : undefined
                        }
                        onChange={(id) => {
                            let newLicensePackage = { ...licensePackageState };
                            newLicensePackage.productId = id;
                            setLicensePackage(newLicensePackage);
                        }}
                        // Product cannot be updated once created
                        disabled={!isCreate}
                        style={{ width: 486, marginRight: 30 }}
                        invalid={submitClicked && !isProductValid}
                    />
                </FormField>
                {getSimpleField(packageKeyField)}
            </div>
            <div style={{ display: "flex" }}>
                <FormField>
                    <label>
                        Number of Users<span style={{ color: "red" }}>&nbsp;*</span>
                    </label>
                    <div>
                        <input
                            id="qa-licensePackage-numUsers"
                            type="number"
                            min="0"
                            max="5000"
                            value={
                                licensePackageState.unlimitedMaxUsers
                                    ? ""
                                    : licensePackageState.maxUsers || 0
                            }
                            disabled={licensePackageState.unlimitedMaxUsers}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                updateField("maxUsers", e, true)
                            }
                            style={{
                                height: 32,
                                width: 80,
                                borderColor: numUsersColor,
                            }}
                        />
                        <Checkbox
                            id="qa-licensePackage-unlimited"
                            label="Unlimited"
                            defaultChecked={licensePackageState.unlimitedMaxUsers}
                            onChange={(e: React.FormEvent<HTMLInputElement>, data) => {
                                const { checked } = data;
                                let newLicensePackage = { ...licensePackageState };
                                newLicensePackage.unlimitedMaxUsers = checked;
                                setLicensePackage(newLicensePackage);
                            }}
                            disabled={licensePackageState.companyWide}
                            style={{ padding: "8px 0px 0px 20px" }}
                        />
                        <Checkbox
                            id="qa-licensePackage-companyWide"
                            label="Company-wide"
                            defaultChecked={licensePackageState.companyWide}
                            onChange={(e: React.FormEvent<HTMLInputElement>, data) => {
                                const { checked } = data;
                                let newLicensePackage = { ...licensePackageState };
                                newLicensePackage.companyWide = checked;
                                newLicensePackage.unlimitedMaxUsers = checked;
                                setLicensePackage(newLicensePackage);
                            }}
                            style={{ padding: "8px 40px 0px 16px" }}
                        />
                        {submitClicked && !isNumUsersValid && (
                            <p className="ui negative message" style={{ marginRight: 40 }}>
                                {fieldRequiredCopy}
                            </p>
                        )}
                    </div>
                </FormField>
                <FormField>
                    <label>
                        Duration<span style={{ color: "red" }}>&nbsp;*</span>
                    </label>
                    <Dropdown
                        id="qa-licensePackage-duration"
                        className={classnames({
                            error: submitClicked && !isDurationValid,
                        })}
                        options={[
                            { key: "eternal", text: "(eternal)", value: "eternal" },
                            { key: "monthly", text: "Monthly", value: "monthly" },
                            { key: "yearly", text: "Yearly", value: "yearly" },
                        ]}
                        placeholder="Select duration"
                        defaultValue={
                            licensePackageState.duration ? licensePackageState.duration : "eternal"
                        }
                        onChange={(e: React.SyntheticEvent<HTMLElement, Event>, data) => {
                            const { value } = data;
                            let newLicensePackage = { ...licensePackageState };
                            // we can't use undefined or null as the key or value in the dropdown
                            // as it confuses the dropdown types and implementation
                            // so "eternal" proxies for undefined in the dropdown
                            newLicensePackage.duration =
                                value === "eternal" ? null : (value as string);
                            setLicensePackage(newLicensePackage);
                        }}
                        style={{ width: 200 }}
                        selection
                    />
                    {submitClicked && !isDurationValid && (
                        <p className="ui negative message" style={{ width: 200 }}>
                            {fieldRequiredCopy}
                        </p>
                    )}
                </FormField>
            </div>
            {getSimpleField(descriptionField)}
            <FormField>
                <Query query={GET_ATTRIBUTES}>
                    {({ loading: isLoading, error, data }: QueryResult<any>) => {
                        if (isLoading) {
                            return <div className="ui loader active" />;
                        }
                        if (error) {
                            Notifier.error(error);
                            return <Redirect to="/" />;
                        }
                        let { listLicenseAttributes } = data;
                        return (
                            <>
                                <label>License Package Attributes</label>
                                {listLicenseAttributes.map(getAttributeCheckbox)}
                            </>
                        );
                    }}
                </Query>
            </FormField>
            <FormField>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "baseline",
                    }}
                >
                    <FormField>
                        <label className="field label" style={{ marginRight: 10 }}>
                            Mail `Expiring Soon` Messages 30, 22, 14 and 6 Days Before Expiration
                        </label>
                    </FormField>
                </div>
            </FormField>
            <FormField key="buttons">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex" }}>
                        <Button
                            id="qa-licensePackage-disable"
                            type="submit"
                            negative={!licensePackageState.disabled}
                            content={licensePackageState.disabled ? "Enable" : "Disable"}
                            onClick={() => {
                                if (isValid) {
                                    licensePackageState.disabled = !licensePackageState.disabled;
                                }
                            }}
                            disabled={isCreate || isDirty || submitClicked}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            id="qa-licensePackage-cancel"
                            type="button"
                            secondary
                            disabled={!isValid && submitClicked}
                            onClick={() => history.push("/license_package")}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="qa-licensePackage-submit"
                            type="submit"
                            primary
                            disabled={!isDirty || (!isValid && submitClicked)}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </FormField>
        </Form>
    );
};
