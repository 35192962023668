import { FunctionComponent, useContext } from "react";
import styled from "grabcad-ui-elements";
import { config } from "../config";
import { ApplicationContext } from "../components/ApplicationProvider";
import Parser from "html-react-parser";

const BodyStyle = styled.div`
    background: #292929 url(https://grabcad.com/static/errors/back-pattern.jpg) repeat;
    color: #999;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 20px;
    font-size: 15px;
    height: 100vh;

    @font-face {
        font-family: "PillGothic600mgLight";
        src: url("https://grabcad.com/static/font/pillgothic600mg-light-webfont.eot");
        src: local("?"),
            url("https://grabcad.com/static/font/pillgothic600mg-light-webfont.woff") format("woff"),
            url("https://grabcad.com/static/font/pillgothic600mg-light-webfont.ttf")
                format("truetype"),
            url("https://grabcad.com/static/font/pillgothic600mg-light-webfont.svg#webfontkEC82GH8")
                format("svg");
        font-weight: normal;
        font-style: normal;
    }
`;

const logout = () => {
    let currentUrl = window.location.href;
    window.location.assign(`${config.REACT_APP_LOGOUT_URL}?return_to=${currentUrl}`);
};

export const PageNotFound: FunctionComponent<any> = (props) => {
    const { t } = useContext(ApplicationContext);

    return (
        <BodyStyle>
            <div
                style={{
                    backgroundImage: `url(${require("../images/404_shop.png")})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "bottom left",
                    marginLeft: 120,
                    paddingLeft: 332,
                    minHeight: 367,
                    backgroundSize: "317px 240px",
                    width: 600,
                }}
            />
            <h1
                style={{
                    color: "#cdcdcd",
                    fontSize: 30,
                    fontFamily: "PillGothic600mgLight, sans-serif",
                    fontWeight: 400,
                    margin: "-235px 0 0 450px",
                    paddingTop: 15,
                }}
            >
                {t("error.page_not_found")}
            </h1>
            <p style={{ margin: "15px 0 0 450px" }}>
                {Parser(t("error.get_help", { email: "info@grabcad.com" }))}
            </p>
            <p style={{ margin: "10px 0 35px 450px" }}>
                {/* Can't use html-react-parser here because of logout callback */}
                {`${t("error.click_here_to")} `}
                <a id="qa-pageNotFound-logout" onClick={logout} style={{ cursor: "pointer" }}>
                    {`${t("error.logout")}.`}
                </a>
            </p>
            <a id="qa-pageNotFound-logo" style={{ marginLeft: 450 }} href="/">
                <img src="https://grabcad.com/static/errors/grabcad-logo.png" />
            </a>
        </BodyStyle>
    );
};
