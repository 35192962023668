type PreferenceValue = string | number | boolean;
export interface Preference {
    key: string;
    value: PreferenceValue[] | PreferenceValue;
}

export const enum ApplicationTypeId {
    print = "print",
    shop = "shop",
    sdk = "sdk",
    api = "api",
    print_manager = "print_manager",
    open_materials = "open_materials",
    analyze = "analyze",
}

export interface ApplicationRole {
    id: number;
    applicationType: ApplicationType;
    name: string;
    description: string;
}

export interface ApplicationType {
    id: string;
    description: string;
    roles: ApplicationRole[];
}

export interface Application {
    id: string;
    name: string;
    applicationType: ApplicationType;
    visible: boolean;
    dateDeleted?: Date;
}

export interface GroupRole {
    application: Application;
    applicationRole: ApplicationRole;
}

export interface UserGroupRoleInput {
    // MAXTODO: Reconsider having all inputs being optional in favor of multiple types
    applicationId?: string;
    role?: string;
    applicationType?: string;
}

export interface User {
    id: number;
    email: string;
    name: string;
    joinDate: Date;
    /**
     * hasRegistered is true if and only if the user has logged in via grabcad
     * So corresponds to grabcad_member_id being set in the backend, but there's
     * no need for the frontend to know those details hence just boolean here
     */
    hasRegistered: boolean;
}

export interface UserGroup {
    id: number;
    users: User[];
    name: string;
    roles: GroupRole[];
    company: Company; // TODO remove when createUserGroup no longer takes company_id
    licenses: LicenseData[];
}

// TODO Share! GC-77462
export enum NotificationType {
    MyJobs = "my_jobs",
    AllJobs = "all_jobs",
}

// TODO Share! GC-77462
export enum UnitSystem {
    Metric = "metric",
    Imperial = "imperial",
}

export type Language = "en" | "es" | "de" | "fr" | "it" | "ru" | "ja" | "ko" | "zh-CN" | "zh-TW";

export interface PrintNotificationsSettings {
    enabled: boolean;
    notificationType: NotificationType;
}

export interface UserSettings {
    printNotifications: PrintNotificationsSettings;
    units: UnitSystem;
    language: Language;
}

export enum Role {
    GlobalAdmin = "Global Administrator",
    CompanyAdmin = "Company Administrator",
    User = "User",
}

// NB: These only indicates that the user's company has access to the feature, NOT that the user is licensed to use it!
export interface CompanyFeatures {
    stigAccess: boolean;
    sdkAccess: boolean;
    accessControl: boolean;
    analyzeAccess: boolean;
}

/**
 * This whole type is (as is mentioned in other TODOs) a rather pointless
 * re-packaging of user and company information into a slightly more convenient form
 * for the UI code
 */
export interface RoleInfo {
    email: string;
    name: string;
    role: Role;
    companyId?: number;
    urlPrefix?: string;
}

/**
 * accountNumber is manually input by Global Admins as "Ship to Customer Account #".
 * It is consumed by Zeus gateways (?) as `externalId` via v1/whoami. It is "external" in that it is a
 * foreign key in the MIS Oracle DB. It _may_ be unique to a Company, or it might be shared; EG Boeing.
 * According to Danny, It is numeric 95% of the time, but occasionally contains letters, so must be treated as a string.
 *
 * We may want to make it a normal column one day, however `accountNumber` is currently stored in company.customerInfo,
 * which is a JSON Blob.
 */
export interface CustomerInfo {
    accountNumber?: string;
    name?: string;
    phoneNumber?: string;
    email?: string;
}

export interface RegionalSite {
    site?: string;
    region?: string;
}

export interface ResellerInfo {
    name?: string;
    phoneNumber?: string;
    email?: string;
    contactPerson?: string;
    accountNumber?: string;
}

export interface CompanySettings {
    timezone?: string;
    locale?: string;
}

export enum CompanyType {
    Reseller = "reseller",
    Customer = "customer",
}

// CASE
export interface CompanyData {
    name: string;
    tosState?: CompanyTOSState;
    urlPrefix?: string;
    admins: string[];
    internal: boolean;
    companyType?: CompanyType;
    customerInfo?: CustomerInfo;
    regionalSite?: RegionalSite;
    resellerInfo?: ResellerInfo;
    settings?: CompanySettings;
    notifyAdmins?: boolean;
    operatingSchedule?: OperatingSchedule;
    analyzeServerAddress?: string;
    licenses?: LicenseData[];
}

export type CreateCompanyFromWbArgs = {
    name?: string;
    accountIds: number[];
    copyAccountName?: boolean;
    notifyUsers?: boolean;
};

export type UpdateCompanyFromWbArgs = {
    id: number;
    accountIds: number[];
    notifyUsers?: boolean;
};

export interface WorkbenchAccount {
    id: number;
    // The name will be missing if the account was deleted or if some other error
    // occurred while trying to fetch the account name
    name?: string;
}

export interface Company extends CompanyData {
    id: number;
    applications: Application[];
    userGroups: UserGroup[];
    accessOptions: CompanyAccessOption[];
}

export interface OperatingSchedule {
    days?: OperatingHours;
    enabled: boolean;
}

export interface ClientData {
    id: number;
    name: string;
    description: string;
    clientId: string;
    clientSecret: string;
}

export interface ExistingClientData {
    id: number;
    name?: string;
    description?: string;
}

export interface Product extends ProductData {
    id: number;
}

export interface LicenseAttribute {
    id: number;
    name: string;
    licensePackages: LicensePackage[];
}

export interface LicensePackage extends LicensePackageData {
    id: number;
}

export interface LicensePackageData {
    name: string;
    description?: string;
    disabled: boolean;
    partNumber?: string;
    externalId?: string;
    product?: Product;
    productId?: number;
    applicationType?: ApplicationType;
    key?: string;
    maxUsers?: number;
    unlimitedMaxUsers?: boolean;
    companyWide?: boolean;
    duration?: string | null;
    expiringMsgDisplayDaysLeft?: number;
    attributes?: LicenseAttribute[];
    attributeIds?: number[];
    companies?: Company[];
    licenses?: LicenseData[];
}

export interface FeatureData {
    id: number;
    key: string;
    displayName: string;
    disabled: boolean;
    applicationTypeId: string;
    applicationType: ApplicationType;
    isPrinterType: boolean;
    isMaterialType: boolean;
    isAssignedViaRoles: boolean;
    products?: Product[];
    __typename: "Feature";
}

export interface ProductData {
    id: number;
    name: string;
    disabled: boolean;
    applicationTypeId?: string;
    applicationType?: ApplicationType;
    features?: FeatureData[];
    featureIdsToAdd?: number[];
    sdk?: Sdk | null;
    companies?: Pick<Company, "id">[];
    packages?: LicensePackage[];
}

export enum LicenseActivationState {
    NOT_STARTED = "not_started",
    ACTIVE = "active",
    EXPIRING = "expiring",
    EXPIRED = "expired",
}

/**
 * Results from GQL query. Must match GQL type `License`, with optional props for sometimes-queried gql props
 */
export interface LicenseData {
    id: number;
    companyId: number;
    package: LicensePackage;
    orderDate: Date;
    startDate: Date;
    endDate: Date | null;
    quantity: number;
    partNumber: string | null;
    externalId?: string | null;
    orderId: string | null;
    orderLineNumber: number | null;
    poNumber?: number | null;
    maxUsers: number;
    comments: string | null;
    resellerSale?: boolean | null;
    seatsUsed: number;
    state: LicenseActivationState;
    machineEntitlements?: MachineEntitlementData[];
    materialEntitlements?: MaterialEntitlementData[];
    renewedLicenseId?: number;

    // This isn't queried or saved - it's derived from endDate - startDate / package.duration in getDefaultTimeSpan().
    // Included here as a convenience for form dirtiness check. A standalone bit of state might be cleaner?
    timeSpan?: number;
}

/**
 * License state for React components *and* input variables for GQL mutations
 *
 * Must match GQL type for mutations.
 * - UpdateLicenseInput [for updateLicense()] and
 * - expanded arguments [for createLicense()]
 */
export type LicenseState = Omit<
    Partial<LicenseData>,
    "materialEntitlements" | "machineEntitlements" | "maxUsers"
> & {
    materialEntitlements?: MaterialEntitlementRowState[];
    machineEntitlements?: MachineEntitlementRowState[];
    licensePackageId?: number;
};

export type MachineEntitlementRowState = MachineEntitlementData & {
    isDuplicate?: boolean;
    isPlaceholder?: boolean;
};

export type MaterialEntitlementRowState = MaterialEntitlementData & {
    isDuplicate?: boolean;
    isPlaceholder?: boolean;
};

export interface LicenseAssignment {
    user: {
        id: number;
        email: string;
        name: string;
    };
    orderId?: string;
    dateAssigned: Date;
    dateRevoked: Date | null;
    __typename: "LicenseAssignment";
}

export interface GroupData {
    id: number;
    name: string;
    roles: {
        application: {
            name: string;
            dateDeleted?: boolean;
        };
        applicationRole: { name: string };
    }[];
    users: User[];
}

export interface DetailedLicenseData extends LicenseData {
    licenseAssignments: LicenseAssignment[];
    groups: GroupData[];
    restrictedUsers?: User[];
}

export enum CompanyAccessOptionsState {
    PENDING_APPROVAL = "pending_approval",
    APPROVED = "approved",
    DENIED = "denied",
}

export interface CompanyAccessOption {
    id: number;
    url: string;
    state: CompanyAccessOptionsState;
    groups: UserGroup[];
    companyWithSameUrl: Company;
    company: {
        id: number;
        name: string;
    };
}

export interface TimeRange {
    start: string;
    end: string;
}

export type Day =
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";

export type OperatingHours = {
    [day in Day]: DayValues;
};

export class DayValues {
    enabled: boolean;
    periods: TimeRange[];
}

export interface Printer extends PrinterData {
    /** ID for internal use with GQL data type. != serial nor secondary_id */
    id: number;
}

// Must match server/src/entities/PrinterVendorType.ts
// TODO Share! GC-77462
export enum PrinterVendorType {
    pj = "pj",
    stratasys = "stratasys",
    pbf = "pbf",
}

export function stringToPrinterVendorType(val: string | null): PrinterVendorType | undefined {
    // NB: TypeScript enum have bidirectional lookup. e.g.
    // PrinterVendorType["pj" as string]               == PrinterVendorType.pj
    // PrinterVendorType[PrinterVendorType.pj as enum] == "pj"

    // cast to PrinterVendorType needed to avoid unnecessarily alarmist compiler complaint.
    // In runtime, it's okay to pass in any string ... lookup will just return undefined.
    return PrinterVendorType[val?.toLowerCase() as PrinterVendorType];
}

/**
 * By convention with grabcad.com and eagle cloud, this number is added to
 * company server company ids to distinguish them from workbench account ids
 */
const WORKBENCH_COMPANY_ID_OFFSET = 1000000000; // 10^9
export function workbenchifyComanyId(id: number): number {
    // first `+` must be there to tell javascript these are numbers, not strings to concatenate!
    return +id + WORKBENCH_COMPANY_ID_OFFSET;
}

// Must match GQL type PrinterInput (for mutations) + GQL type Printer (for queries)
// Must match server/src/services/printers.ts
export interface PrinterData {
    serial: string;
    secondaryId?: string;
    type?: string;
    vendor: string;
    /**
     * Name of the printer. This is nullable in the db hence | null
     * It is optional because when specified as part of an update, undefined
     * implies to leave the field as is
     */
    name?: string;
}

/**
 * Status of a printer. Note that these are loosely a subset of the generic printer statuses located at `https://github.com/GrabCAD/eagle-print/blob/master/grabcad-print-core/model/generic_printer_status.ts`, and as such this should probably be extracted into a common repo and/or NPM package/library (perhaps added to `grabcad-printers-api`).
 */
export enum PrinterStatus {
    Idle = "Idle",
    PendingStart = "Pending Start",
    Printing = "Printing",
    PrintCompleted = "Print Completed",
    OutOfMaterial = "Out of Material",
    Paused = "Paused",
    Cancelled = "Cancelled",
    Error = "Error",
}

/**
 * Colors associated with printer statuses.
 */
export enum PrinterStatusColor {
    Gray = "#999999",
    Blue = "#33aadd",
    Green = "#57b857",
    Orange = "#ff8800",
    Red = "#ff4433",
}
export interface PrinterGroup {
    id: number;
    name: string;
    printerIds: string[];
}

export interface UserGroupPrinterGroupAccessOutput {
    id: number;
    userGroup: { id: number };
    printerGroup: { id: number };
}

export interface PrinterGroupData {
    printers: PrinterData[];
}

export type SdkIcon = "printer_connectivity_sdk.svg" | "print_sdk.svg";
export type SdkName = "connectivity" | "print";

export interface Sdk {
    readonly id: number;
    readonly name: SdkName;
    readonly productName: string;
    readonly filename: string;
    readonly keyPrefix: string;
    readonly icon: SdkIcon;
}

export interface SdkData {
    id: number;
    name: SdkName;
    productName: string;
    url: string;
    version: string;
    lastModified: string;
    icon: SdkIcon;
}

export interface StigData {
    id: number;
    name: string;
    url: string;
    version?: string;
    lastModified?: string;
    sha256?: string;
    releaseNotesLink: string;
}

export interface MachineEntitlementCreateData {
    serial: string | null;
    technology: string | null;
    model: string | null;
}

export interface MachineEntitlementData extends MachineEntitlementCreateData {
    id?: number;
}
export interface MaterialEntitlementCreateData {
    technology: string | null;
    name: string | null;
}

export interface MaterialEntitlementData extends MaterialEntitlementCreateData {
    id?: number;
}

/**
 * A Feature is a simply a string that is consumed in a client application to provide functionality
 *
 * In company-server, features are usually bundled together into Products, which are then
 * grouped into Licenses via LicensePackage and License
 *
 * Also see https://github.com/GrabCAD/company-server/blob/master/docs/Licensing.md
 */
export interface Feature {
    readonly id: number;
    readonly displayName: string;
    readonly key: string;
    readonly disabled: boolean;
    readonly application_type_id: ApplicationTypeId;
    readonly isPrinterType?: boolean;
    readonly isMaterialType?: boolean;
    readonly isAssignedViaRoles?: boolean;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly version: number;
}
/**
 * Keeping property names consistent with AWS internals
 * Also see  https://docs.aws.amazon.com/iot/latest/developerguide/iot-thing-management.html
 */
export interface IGateway {
    coreDeviceThingName: string;
    site: string;
    status: string;
    associatedClientDevices: AssociatedClientDevice[];
    url?: string;
    externalCompanyId?: string;
}
/**
 * Printer device connected with gateway
 */
export enum ClientDeviceType {
    Printer = "Printer",
    AnalyzeOnPrem = "AnalyzeOnPrem",
}

export interface AssociatedClientDevice {
    thingName: string;
    status?: string;
    printerType?: string;
    thingType?: ClientDeviceType;
    printerTechnology?: string;
    externalPrinterId?: string;
    resellerCompanyId?: string;
}

/*
 * This describes _all_ Client Devices from /api/public/client-devices, including "orphaned" Devices without `coreDeviceThingName` defined.
 * See https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md#request-get-list-of-printers,
 */
export interface MaybeAssociatedClientDevice extends AssociatedClientDevice {
    coreDeviceThingName?: string;
}

export interface JobIdDto {
    jobId?: string;
    printerSerial?: string;
}

export interface CreateGatewayResult {
    externalCompanyId: string;
    coreDeviceThingName: string;
}

export type GatewayInstallerStatus =
    | "NOT_REQUESTED"
    | "IN_PROGRESS"
    | "COMPLETED"
    | "FAILED"
    | "HEALTHY"
    | "NEW"
    | "UNHEALTHY";

export interface GatewayInstallerJobStatusResult {
    jobId: string;
    status: GatewayInstallerStatus;
    url?: string; // Public URL to download installer from. Only present if job has COMPLETED status.
}

export interface GetGatewayInstallerURL {
    status: GatewayInstallerStatus;
    url?: string; // Public URL to download installer from. Only present if job has COMPLETED status.
}

export interface ICreateGatewayVariables {
    site: string;
    region: string;
    printerSerial: string;
}

export interface IRequestGatewayInstallerVariables {
    coreDeviceThingName: string;
    printerSerial: string;
}

export interface IListGatewayVariables {
    printerId: string;
}

export interface WhoAmI {
    id?: number;
    name?: string;
    settings?: {
        units?: string;
        language?: string;
        printNotifications?: {
            enabled?: boolean;
            notificationType?: string;
        };
    };
    isGlobalAdmin?: boolean;
    company?: {
        id?: number;
        name?: string;
        urlPrefix?: string | null;
        isAdmin?: boolean;
        externalId?: string;
    };
    workbenchAccountId?: number | null;
}

export type WithOverrides<T, Overrides extends { [K in keyof T]?: unknown }> = {
    [K in keyof T]: K extends keyof Overrides ? Overrides[K] : T[K];
};

/**
 * This type builds up a union of deep keys in a type with the given separator string. Only keys whose values are
 * subtypes of `AllowedVals` will be included.
 * Given an example type T:
 *
 * type T = {
 *     a: {
 *         b: number;
 *     };
 *     c: number;
 *     d: {
 *         e: {
 *             f: string;
 *             g: boolean;
 *         };
 *     };
 * };
 *
 * DeepKeyof<T> will be "a.b" | "c" | "d.e.f" with defaults Separator = "." and AllowedVals = "string" | "number".
 *
 * The condition `K extends string | number` does two things:
 *      1. It ensures that K is not a symbol, which cannot be converted to a template literal type
 *      2. It forces Typescript to distribute over K - so while `keyof T` might be a union of keys,
 *          `K` in the body of the condition will only ever be one member of the union. Typescript distributes
 *          over naked type arguments in conditional types: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
 *
 * The positive branch in the condition builds up the union of deep keys - if the value type for the current key K is one
 * of the allowed vals, then include it in the union. Finally, we don't want to include deep keys for wrapped
 * primitives, so we check if T[K] is a primitive and stop if it is.
 *
 * Wrapped primitives are actually objects, and Typescript will convert a primitive type to its wrapped counterpart
 * (`number` -> `Number`, `string` -> `String`, etc.) when using `keyof`. We don't want to do this, because the wrapped
 * primitives have methods, and we would end up with keys like 'a.b.toString', 'd.e.f.length', etc. This document
 * explains the difference between primitives and wrapped primitives in some detail: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Data_structures#primitive_values
 *
 * In the negative branch of the last condition, `DeepKeyof<T[K], ...>` will be a union of deep keys of T[K].
 * Constructing a template literal type with a union will cause Typescript to distribute over the union.
 */
export type DeepKeyof<
    T,
    Separator extends string = ".",
    AllowedVals = string | number,
    K extends keyof T = keyof T
> = K extends string | number
    ?
          | (T[K] extends AllowedVals ? `${K}` : never)
          | (T[K] extends Primitive
                ? never
                : `${K}${Separator}${DeepKeyof<T[K], Separator, AllowedVals>}`)
    : never;

type Primitive = string | number | boolean | bigint | symbol | null | undefined;

export type CompanyTOSState = "NOT_SEEN" | "SEEN" | "ACCEPTED";

export interface UpdateAnalyzeAddressOptions {
    analyzeAddressUrl: string | undefined;
}
